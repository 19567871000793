import styled from 'styled-components';
import { Row as _Row } from 'antd';
const Row = styled(_Row) `
	&:not(:last-child) {
		margin-bottom: ${({ gutter }) => (gutter ? (gutter instanceof Array ? gutter[1] : gutter) : 0)}px;
	}
`;
export default {
    Row,
};
