import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import LayoutHost from './host';
const LayoutContext = createContext(() => {
    /* */
});
export function createLayoutManager(renderFn) {
    const Provider = ({ children }) => {
        const ref = useRef(null);
        const update = useCallback((state) => {
            if (ref.current) {
                ref.current.setState(state);
            }
        }, []);
        return (React.createElement(LayoutContext.Provider, { value: update },
            React.createElement(LayoutHost, { ref: ref, renderFn: renderFn }),
            children));
    };
    const Consumer = ({ contentKey, type, children, systemMessage }) => {
        const update = useContext(LayoutContext);
        useEffect(() => {
            update({ contentKey, type, children, systemMessage });
        });
        return null;
    };
    return { Provider, Consumer };
}
