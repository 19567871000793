/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { color, height } from '@libs/theme/mixins';
const SIZE_MAP = {
    small: 'xs',
    middle: 'sm',
    large: 'md',
};
const Wrapper = styled(forwardRef((props, ref) => React.createElement(Button, Object.assign({ ref: ref }, props)))) `
	width: ${({ size = 'middle' }) => height(SIZE_MAP[size])} !important;
	min-width: ${({ size = 'middle' }) => height(SIZE_MAP[size])} !important;
	height: ${({ size = 'middle' }) => height(SIZE_MAP[size])} !important;
	padding: 0 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ danger }) => danger &&
    css `
			border-color: ${color('error')} !important;
			color: ${color('error')} !important;
		`}
`;
export default {
    Wrapper,
};
