import React, { useEffect, useRef } from 'react';
import Styled from './Styled';
const Sticky = ({ targetSelector, enabled = true, offsetTop = 0, offsetBottom = 0, children }) => {
    const contentRef = useRef(null);
    useEffect(() => {
        const setPushTop = (value) => {
            if (contentRef.current) {
                contentRef.current.style.transform = `translateY(${value}px)`;
            }
        };
        const scrollingElement = document.scrollingElement;
        if (contentRef.current && scrollingElement && enabled) {
            const targetEl = (targetSelector && contentRef.current.querySelector(targetSelector)) || contentRef.current;
            const originTop = targetEl.getBoundingClientRect().top + scrollingElement.scrollTop;
            const updatePosition = () => {
                const stickyOriginTop = offsetTop;
                const stickySwingDelta = Math.max(stickyOriginTop + targetEl.clientHeight + offsetBottom - window.innerHeight, 0);
                const stickyStartTop = originTop - stickyOriginTop + stickySwingDelta;
                const pushTop = Math.max(scrollingElement.scrollTop - stickyStartTop, 0);
                setPushTop(pushTop);
            };
            // Update initial position
            updatePosition();
            // Update position on subsequent scroll events
            document.addEventListener('scroll', updatePosition);
            return () => {
                document.removeEventListener('scroll', updatePosition);
            };
        }
        else {
            setPushTop(0);
        }
    }, [targetSelector, enabled, offsetTop, offsetBottom]);
    return (React.createElement(Styled.Wrapper, null,
        React.createElement("div", { ref: contentRef }, children)));
};
export default Sticky;
