import http, { endpoints } from '../http';
import { mapPackageFromDto, mapProductFromDto } from './helpers';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class PackagesApi {
    static async getPackages(branchId) {
        var _a;
        const response = await datahubClient.get(`/branches/${branchId}/packages`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapPackageFromDto)) || [];
    }
    static async getAdditionalProducts(branchId) {
        var _a;
        const response = await datahubClient.get(`/branches/${branchId}/products/alacarte`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapProductFromDto)) || [];
    }
    static async getEmptyPackageProducts(branchId) {
        var _a;
        const response = await datahubClient.get(`/branches/${branchId}/products/list`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapProductFromDto)) || [];
    }
}
