var _a, _b;
import i18next from 'i18next';
const localStorage = typeof window === 'undefined' ? undefined : window.localStorage;
const PREFERRED_LOCALE_KEY = 'locale';
const isSEDomain = typeof window !== 'undefined' && window.location.host.includes('.se');
const DEFAULT_LOCALE = isSEDomain ? 'sv' : (_a = process.env.DEFAULT_LOCALE) !== null && _a !== void 0 ? _a : 'en';
const FALLBACK_LOCALE = (_b = process.env.FALLBACK_LOCALE) !== null && _b !== void 0 ? _b : DEFAULT_LOCALE;
function getPreffered() {
    var _a;
    return ((_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(PREFERRED_LOCALE_KEY)) !== null && _a !== void 0 ? _a : (typeof navigator !== 'undefined' ? navigator.language : DEFAULT_LOCALE));
}
function getFallback() {
    return FALLBACK_LOCALE;
}
function getCurrent() {
    return i18next.language;
}
function setCurrent(locale) {
    if (typeof window !== 'undefined') {
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem(PREFERRED_LOCALE_KEY, locale);
    }
    i18next.changeLanguage(locale);
}
function getCurrentHierarchy() {
    const locale = getCurrent();
    return locale === FALLBACK_LOCALE ? locale : [locale, FALLBACK_LOCALE];
}
export default {
    getPreffered,
    getCurrent,
    setCurrent,
    getCurrentHierarchy,
    getFallback,
};
