import styled from 'styled-components';
import React from 'react';
import Container from '../Container';
const Wrapper = styled(props => React.createElement(Container, Object.assign({}, props))) `
	.ant-input-number {
		width: 50px;
		flex: 0 0 50px;

		input {
			text-align: center;
		}
	}

	.ant-input-number-handler-wrap {
		display: none;
	}
`;
export default {
    Wrapper,
};
