import dayjs from 'dayjs';
export function mapNewsFromDto({ content, date, id, teaser, title }) {
    return {
        id,
        title,
        subTitle: teaser,
        content,
        date: dayjs(date, 'DD-MM-YYYY').toDate(),
    };
}
