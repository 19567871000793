/* eslint @typescript-eslint/no-explicit-any: 0 */
import { createContext, useContext } from 'react';
export function createEventBusHook() {
    const listeners = new Map();
    const getListeners = (eventName) => {
        const genericEventName = eventName;
        if (!listeners.has(genericEventName)) {
            listeners.set(genericEventName, []);
        }
        return listeners.get(genericEventName);
    };
    const subscribe = (eventName, listener) => {
        getListeners(eventName).push(listener);
        return () => {
            const index = getListeners(eventName).indexOf(listener);
            if (index !== -1) {
                getListeners(eventName).splice(index, 1);
            }
        };
    };
    const dispatch = (eventName, payload) => {
        getListeners(eventName).forEach(listener => listener(payload));
    };
    const Context = createContext({
        subscribe,
        dispatch,
    });
    return function useEventBus() {
        return useContext(Context);
    };
}
