import React from 'react';
import styled, { css } from 'styled-components';
import { margin, color, padding } from '@libs/theme/mixins';
import { Tooltip as AntdTooltip } from 'antd';
const TooltipOverlayMapped = ({ className, ...tooltipProps }) => (React.createElement(AntdTooltip, Object.assign({ overlayClassName: className }, tooltipProps)));
const Tooltip = styled(TooltipOverlayMapped) `
	${({ disabled }) => disabled &&
    css `
			display: none;
		`}

	${({ maxWidth }) => maxWidth &&
    css `
			max-width: ${maxWidth}px;
		`}
`;
const TooltipContent = styled.div `
	padding: ${padding('xxs')};

	img {
		display: block;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
	}

	p {
		margin-top: ${margin('xxs')};

		${({ lightTheme }) => lightTheme &&
    css `
				color: ${color('black')};
			`}
	}
`;
const TooltipChildren = styled.div `
	${({ display }) => display === 'inline' &&
    css `
			display: inline;
		`}

	svg {
		display: block;
		color: ${color(['gray', '700'])};

		&:hover {
			color: ${color(['primary', '500'])};
			cursor: pointer;
		}
	}
`;
export default {
    Tooltip,
    TooltipContent,
    TooltipChildren,
};
