import React from 'react';
import Skeleton from '../../Skeleton';
import { useHideModal } from '../context';
import { Close24 } from '@carbon/icons-react';
import Styled from './Styled';
const ArticleModal = ({ title, subtitle, loading, width = '80vw', fullHeight, children }) => {
    const hideModal = useHideModal();
    return (React.createElement(Styled.Modal, { visible: true, width: width, closeIcon: React.createElement(Close24, null), footer: null, fullHeight: fullHeight, title: loading ? (React.createElement(Skeleton.Paragraph, { active: true, rows: 0, titleWidth: '200px' })) : (React.createElement(Styled.Title, null,
            React.createElement("div", null, title),
            subtitle && React.createElement("div", null, subtitle))), onCancel: hideModal }, loading ? (React.createElement(Skeleton.Paragraph, { active: true, rows: 5, titleWidth: '100%', rowWidth: ['100%', '100%', '75%', '50%'] })) : (React.createElement(Styled.Content, null, children))));
};
export default ArticleModal;
