import * as yup from 'yup';
import { createTranslationLink } from '@libs/localization';
// Basic validation
const phoneRegExp = /^(?:\+\d{1,3}\s?)?(?:\(\d{1,4}\)\s?)?[\d\s.-]{6,}$/g;
const phonePrefixRegExp = /^\+[0-9]{2,3}$/;
const passwordRegExp = /^(?=.*[!”#¤@%&/()$*^A-Za-zæøåÆØÅÖöÉé])(?=.*\d)[!”#¤@%&/()$*^A-Za-zæøåÆØÅÖöÉé\d]{8,}$/;
const emailRegExp = /([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])/i;
export function isPhonePrefixOnly(value) {
    return !!(value === null || value === void 0 ? void 0 : value.match(phonePrefixRegExp));
}
export function isValidPhoneNumber(value) {
    return !!(value === null || value === void 0 ? void 0 : value.match(phoneRegExp));
}
export function isValidPassword(value) {
    return !!(value === null || value === void 0 ? void 0 : value.match(passwordRegExp));
}
export function isValidEmail(value) {
    return !!(value === null || value === void 0 ? void 0 : value.match(emailRegExp));
}
export function isNumeric(value) {
    return !!(value === null || value === void 0 ? void 0 : value.match(/^[0-9]+$/));
}
// Yup custom methods
yup.addMethod(yup.string, 'businessEmail', function () {
    return this.test('is business email', createTranslationLink('validation.custom.businessEmail'), function (value = '') {
        var _a, _b;
        const oldDomain = (_b = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.email) === null || _b === void 0 ? void 0 : _b.substr(this.parent.email.indexOf('@'));
        const newDomain = value.substr(value.indexOf('@'));
        return oldDomain === newDomain;
    });
});
yup.addMethod(yup.string, 'phone', function () {
    return this.test('is phone number', createTranslationLink('validation.custom.phone'), function (value = '') {
        return !value || isValidPhoneNumber(value) || isPhonePrefixOnly(value);
    });
});
yup.addMethod(yup.string, 'password', function () {
    return this.test('is password', createTranslationLink('validation.custom.password'), function (value = '') {
        return isValidPassword(value);
    });
});
yup.addMethod(yup.string, 'matchesField', function (otherFieldPath) {
    const translateFieldName = createTranslationLink(`common.terms.${otherFieldPath}`);
    return this.test(`matches ${otherFieldPath}`, createTranslationLink('validation.custom.matchesField', { otherField: translateFieldName().toLowerCase() }), function (value = '') {
        const otherFieldValue = this.parent[otherFieldPath];
        return value === otherFieldValue;
    });
});
yup.addMethod(yup.string, 'numeric', function () {
    return this.test('is numeric', createTranslationLink('validation.custom.numeric'), function (value = '') {
        return value.length ? isNumeric(value) : true;
    });
});
// Yup create schema function
// By using this instead of using yup directly we make sure that the custom methods are added before any schema is created
// therefore elimitating the concurrency issue we've been having in the CI builds
export function createValidationSchema(builder) {
    return builder(yup);
}
