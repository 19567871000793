// Import dayjs locales
import 'dayjs/locale/en';
import 'dayjs/locale/da';
import 'dayjs/locale/sv';
import 'dayjs/locale/nb';
function makeResource(translation) {
    return { translation };
}
export default {
    en: makeResource(require('../resources/en.json')),
    da: makeResource(require('../resources/da.json')),
    sv: makeResource(require('../resources/sv.json')),
    no: makeResource(require('../resources/no.json')),
};
