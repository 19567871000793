import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';
import { color, easing, margin, padding, screen, scrollbar } from '@libs/theme/mixins';
const Wrapper = styled(Row) `
	height: 100%;
	position: relative;
	overflow-y: hidden;
	background: ${color(['gray', '000'])};
`;
const Content = styled(Col) `
	background: ${color(['gray', '100'])};
	overflow-y: auto;
	min-height: 100vh;

	// Hide scrollbar while transitioning
	${({ $transitioning }) => css `
		${scrollbar(!$transitioning)}
	`}

	&, & >* {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		align-items: stretch;
	}

	& > * {
		flex-grow: 1;
		flex-shrink: 1;
	}
`;
const Aside = styled(Col) `
	height: 100%;
	max-height: 100vh;
	overflow-y: hidden;
	${screen.lte.lg({ display: 'none' })}

	${({ $initialized }) => $initialized &&
    css `
			position: fixed;
			right: 0;
			top: 0;
			bottom: 0;
		`}
`;
const Children = styled.div `
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	flex-grow: 1;
	flex-shrink: 0;
	padding: ${padding('xxl', 'xl')};

	${screen.lte.xs `
		padding: ${padding('xxl', 'md')};
	`}

	// Transition animations
	&.transition-enter {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		min-height: 100%;
		opacity: 0;
		transform: translateY(${({ $trackLength }) => `${$trackLength}px`});
	}

	&.transition-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: all ${({ $timeout }) => `${$timeout}ms`} ${easing('in')};
	}

	&.transition-exit {
		position: relative;
		opacity: 1;
		transform: translateY(0);
	}

	&.transition-exit-active {
		opacity: 0;
		transform: translateY(${({ $trackLength }) => `${$trackLength}px`});
		transition: all ${({ $timeout }) => `${$timeout}ms`} ${easing('in')};
	}
`;
const Footer = styled.div `
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	left: 0;
	right: 0;
	bottom: ${margin('md')};
`;
export default {
    Wrapper,
    Content,
    Aside,
    Children,
    Footer,
};
