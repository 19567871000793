/* eslint @typescript-eslint/no-explicit-any: 0 */
import axios from 'axios';
import { mapTranslationsToResource } from './helpers';
import clone from 'lodash/cloneDeep';
import * as qs from 'qs';
import i18n from 'i18next';
// Client side resource loader that pulls live translations from POEditor
export default class PoeLoader {
    constructor(languageCodes, defaultResources) {
        this.languageCodes = languageCodes;
        this.defaultResources = defaultResources;
        this.status = 'idle';
        this.subscribers = [];
        this.http = axios.create({
            baseURL: `${process.env.DATAHUB_API_ENDPOINT}/Poe`,
        });
    }
    async getTranslations(languageCode) {
        const response = await this.http.get(`?${qs.stringify({
            api_token: process.env.POE_READ_TOKEN,
            id: process.env.POE_PROJECT_ID,
            language: languageCode,
        })}`);
        return response === null || response === void 0 ? void 0 : response.data.map(({ term, context, translation }) => ({
            key: term,
            context,
            content: translation.content,
        }));
    }
    async getResources() {
        const translations = await Promise.all(this.languageCodes.map(code => this.getTranslations(code)));
        return this.languageCodes.reduce((acc, code, index) => {
            acc[code] = mapTranslationsToResource(translations[index], clone(this.defaultResources[code]));
            return acc;
        }, {});
    }
    subscribe(subscriber) {
        this.subscribers.push(subscriber);
        if (this.status === 'idle') {
            this.status = 'loading';
            this.getResources()
                .then(resources => {
                Object.keys(resources).forEach(code => {
                    i18n.addResourceBundle(code, 'translation', resources[code].translation);
                });
                this.status = 'loaded';
                this.subscribers.forEach(sub => sub());
            })
                .catch(err => {
                console.warn(`Could not load POEditor languages: ${err.message}`);
            });
        }
        return () => {
            const index = this.subscribers.indexOf(subscriber);
            if (index !== -1) {
                this.subscribers.splice(index, 1);
            }
        };
    }
}
