import React from 'react';
import { useFormikContext } from 'formik';
import Styled from './Styled';
import FormikInput from '../FormikInput';
const FilterInput = props => {
    const { name } = props;
    const { values, setFieldValue } = useFormikContext();
    return (React.createElement(FormikInput, Object.assign({}, props, { suffix: typeof values[name] === 'string' && values[name].length > 0 ? (React.createElement(Styled.ClearTextSuffix, { onClick: () => setFieldValue(name, '') })) : (React.createElement(Styled.SearchIconSuffix, null)) })));
};
export default FilterInput;
