import http, { endpoints, AUTH_LOGOUT_KEY, AUTH_SCHEMA_KEY, IS_ESOFT_USER_KEY, HEADER_USER_ID_KEY, AUTH_RESET_PASSWORD_KEY, RESET_PASSWORD_CODE_KEY, AUTH_LOGIN_KEY, } from '../http';
import { mapUserFromDto } from '../users/helpers';
import { LocalizedError } from '@libs/localization';
import decode from 'jwt-decode';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class AuthApi {
    static isAuthenticated() {
        return !!http.getAuthToken();
    }
    static deauthenticate() {
        http.forgetAuthToken();
    }
    static getUserFromToken() {
        const token = http.getAuthToken();
        return token && decode(token);
    }
    static async getAuthenticatedUser() {
        var _a;
        const token = http.getAuthToken();
        if (!token) {
            return undefined;
        }
        const authUserResponse = await datahubClient.get('/Authentication/user');
        const authUser = authUserResponse === null || authUserResponse === void 0 ? void 0 : authUserResponse.data;
        if (!(authUser === null || authUser === void 0 ? void 0 : authUser.email)) {
            throw new LocalizedError('auth.pages.activeDirectory.errorGettingUser');
        }
        const branchesResponse = await datahubClient.get('/Authentication/user/branches');
        const detailedBranches = (branchesResponse === null || branchesResponse === void 0 ? void 0 : branchesResponse.data) || [];
        const userResponse = await datahubClient.get(`/User/id/${authUser.currentlyImpersonatingUserId || authUser.id}`);
        const user = userResponse === null || userResponse === void 0 ? void 0 : userResponse.data;
        const resultBranches = ((_a = user === null || user === void 0 ? void 0 : user.branches) === null || _a === void 0 ? void 0 : _a.map(branch => {
            const foundDetailedBranch = detailedBranches.find(detailedBranch => detailedBranch.id === branch.id);
            if (foundDetailedBranch) {
                return {
                    ...foundDetailedBranch,
                    ...branch,
                    country: branch.countryCode || branch.country || foundDetailedBranch.country,
                };
            }
            return branch;
        })) || [];
        return mapUserFromDto({ ...authUser, settings: user.settings }, resultBranches);
    }
    static async getShineToken() {
        const token = http.getAuthToken();
        const dto = {
            TokenValue: token || '',
            HashValue: '',
        };
        return dto;
    }
    static async b2cAuth(email, appRoute) {
        const response = await datahubClient.get(`/Authentication/login-url?redirectUrl=${`${window.location.origin}${appRoute}`}&email=${email}&nonce=esoft-test`);
        const data = response === null || response === void 0 ? void 0 : response.data;
        localStorage.setItem(IS_ESOFT_USER_KEY, 'false');
        localStorage.setItem(AUTH_SCHEMA_KEY, data === null || data === void 0 ? void 0 : data.authenticationScheme);
        localStorage.setItem(AUTH_LOGIN_KEY, data === null || data === void 0 ? void 0 : data.loginUrl);
        localStorage.setItem(AUTH_LOGOUT_KEY, data === null || data === void 0 ? void 0 : data.logoutUrl);
        localStorage.setItem(AUTH_RESET_PASSWORD_KEY, data === null || data === void 0 ? void 0 : data.resetPasswordUrl);
        (data === null || data === void 0 ? void 0 : data.resetPasswordErrorCode) && localStorage.setItem(RESET_PASSWORD_CODE_KEY, data.resetPasswordErrorCode);
        localStorage.setItem(HEADER_USER_ID_KEY, JSON.stringify({ header: data === null || data === void 0 ? void 0 : data.impersonatingUserIdHeaderName }));
        return data;
    }
    static async esoftAuth(appRoute) {
        const response = await datahubClient.get(`/Authentication/login-esoft-url?redirectUrl=${`${window.location.origin}${appRoute}`}&nonce=esoft-test`);
        const data = response === null || response === void 0 ? void 0 : response.data;
        localStorage.setItem(IS_ESOFT_USER_KEY, 'true');
        localStorage.setItem(AUTH_SCHEMA_KEY, data === null || data === void 0 ? void 0 : data.authenticationScheme);
        localStorage.setItem(AUTH_LOGIN_KEY, data === null || data === void 0 ? void 0 : data.loginUrl);
        localStorage.setItem(AUTH_LOGOUT_KEY, data === null || data === void 0 ? void 0 : data.logoutUrl);
        localStorage.setItem(AUTH_RESET_PASSWORD_KEY, data === null || data === void 0 ? void 0 : data.resetPasswordUrl);
        (data === null || data === void 0 ? void 0 : data.resetPasswordErrorCode) && localStorage.setItem(RESET_PASSWORD_CODE_KEY, data.resetPasswordErrorCode);
        localStorage.setItem(HEADER_USER_ID_KEY, JSON.stringify({ header: data === null || data === void 0 ? void 0 : data.impersonatingUserIdHeaderName }));
        return data;
    }
    static persistToken(token) {
        http.setAuthToken(token, false);
    }
    static clearAuthHeaders() {
        try {
            const userIdHeader = JSON.parse(localStorage.getItem(HEADER_USER_ID_KEY) || '');
            (userIdHeader === null || userIdHeader === void 0 ? void 0 : userIdHeader.value) && localStorage.setItem(HEADER_USER_ID_KEY, JSON.stringify({ header: userIdHeader.header }));
        }
        catch (ex) {
            // noop
        }
    }
}
