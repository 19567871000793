import http, { endpoints } from '../http';
import { mapLinkOrDocumentMaterialFromDto, mapLinkOrDocumentMaterialToDto, mapSimplifiedVisualMaterialFromDto, } from './helpers';
import { LocalizedError } from '@libs/localization';
const datahubClient = http.createClient(endpoints.datahub);
export default class MaterialApi {
    static async sendDownloadInfo(params) {
        var _a;
        const response = await datahubClient.post('/Assets/download-link/ewarp-materials/email', params);
        if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.success)) {
            throw new LocalizedError('materials.messages.packageZipFileFailed');
        }
        return response.data;
    }
    static async archive(caseId) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/archive`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getSimplifiedVisualMaterials(id) {
        var _a, _b, _c;
        const response = await datahubClient.get(`/cases/${id}/assets`);
        return {
            batchList: ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.batchProductInfos) || [],
            links: ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.links) || [],
            materials: ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.materials.map(mapSimplifiedVisualMaterialFromDto)) || [],
        };
    }
    static async updateMaterials({ caseId, ...params }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/sortorder`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async copyMaterials({ caseId, ...params }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/copy`, {
            ...params,
            copyAll: params.copyAll,
            copyCopyWriteTexts: false,
            copyDocuments: false,
            copyLinks: false,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getLinkMaterials(caseId) {
        var _a;
        const response = await datahubClient.get(`/cases/${caseId}/assets/links`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapLinkOrDocumentMaterialFromDto)) || [];
    }
    static async getDocumentMaterials(caseId) {
        var _a;
        const response = await datahubClient.get(`/cases/${caseId}/assets/documents`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapLinkOrDocumentMaterialFromDto)) || [];
    }
    static async saveLinkAsset({ caseId, assets }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/link`, assets.map(mapLinkOrDocumentMaterialToDto));
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async saveDocumentAsset({ caseId, assets }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/document`, assets.map(mapLinkOrDocumentMaterialToDto));
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async deleteLinkAsset({ caseId, ...params }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/delete`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async combineImages({ caseId, ...params }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/combine-images`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getUploadedMaterialForOrderLine(orderlineId) {
        const response = await datahubClient.get(`/orders/orderlines/${orderlineId}/taskfileuploads`);
        return (response === null || response === void 0 ? void 0 : response.data) || [];
    }
    static async sealBatchUploadedMaterial({ orderlineId, uploads }) {
        const response = await datahubClient.post(`/orders/orderlines/${orderlineId}/materials/add`, uploads);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async registerBatchMaterial({ orderlineId, ...params }) {
        const response = await datahubClient.post(`/orders/orderlines/${orderlineId}/taskfileuploads/add`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async removeBatchMaterial({ materialId }) {
        const response = await datahubClient.delete(`/orders/orderlines/taskfileuploads/${materialId}`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getSuggestedMaterialNames(caseId) {
        var _a;
        const response = await datahubClient.get(`/cases/${caseId}/assets/allowednames`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(({ value }) => value)) || [];
    }
    static async recreateMaterials(caseId) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/restore`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async materialBatchRemove(params) {
        const response = await datahubClient.delete(`/cases/${params.caseId}/assets/batches/${params.batchId}`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getCollagePrice(caseId) {
        const response = await datahubClient.get(`/cases/${caseId}/assets/collage/pricing`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async submitCollage({ caseId, ...params }) {
        const response = await datahubClient.post(`/cases/${caseId}/assets/collage`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async deleteMaterialsAndAssets(orderLineIds) {
        const response = await datahubClient.delete('/Assets/delete-materials-and-assets', {
            data: orderLineIds,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
}
