import { Component } from 'react';
export default class LayoutHost extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        const { contentKey, type, children, systemMessage } = this.state;
        return contentKey !== undefined && type !== undefined
            ? this.props.renderFn(contentKey, type, children, systemMessage)
            : null;
    }
}
