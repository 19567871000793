export var BookingStatus;
(function (BookingStatus) {
    BookingStatus["INCOMPLETE"] = "INCOMPLETE";
    BookingStatus["RECORDED"] = "RECORDED";
    BookingStatus["CONFIRMED"] = "CONFIRMED";
    BookingStatus["FIXED"] = "FIXED";
    BookingStatus["ON_ROUTE"] = "ON_ROUTE";
    BookingStatus["ARRIVED"] = "ARRIVED";
    BookingStatus["COMPLETE"] = "COMPLETE";
    BookingStatus["CANCELLED"] = "CANCELLED";
    BookingStatus["UNKNOWN"] = "UNKNOWN";
})(BookingStatus || (BookingStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["Open"] = "OPEN";
    OrderStatus["Delivered"] = "DELIVERED";
    OrderStatus["Cancelled"] = "CANCELLED";
    OrderStatus["Invoiced"] = "INVOICED";
    OrderStatus["None"] = "NONE";
    OrderStatus["Backorder"] = "BACKORDER";
})(OrderStatus || (OrderStatus = {}));
export var SimplifiedOrderStatus;
(function (SimplifiedOrderStatus) {
    SimplifiedOrderStatus["Cancelled"] = "CANCELLED";
    SimplifiedOrderStatus["Completed"] = "COMPLETED";
    SimplifiedOrderStatus["Ongoing"] = "ONGOING";
    SimplifiedOrderStatus["Unknown"] = "UNKNOWN";
})(SimplifiedOrderStatus || (SimplifiedOrderStatus = {}));
export var FeedbackType;
(function (FeedbackType) {
    FeedbackType["Change"] = "CHANGE";
    FeedbackType["Complaint"] = "COMPLAINT";
    FeedbackType["Feedback"] = "FEEDBACK";
})(FeedbackType || (FeedbackType = {}));
export var FeedbackDrawingShape;
(function (FeedbackDrawingShape) {
    FeedbackDrawingShape["Point"] = "POINT";
    FeedbackDrawingShape["Arrow"] = "ARROW";
    FeedbackDrawingShape["Lasso"] = "LASSO";
})(FeedbackDrawingShape || (FeedbackDrawingShape = {}));
export var OrderLineType;
(function (OrderLineType) {
    OrderLineType["Visual"] = "VISUAL";
    OrderLineType["Text"] = "TEXT";
})(OrderLineType || (OrderLineType = {}));
