export var TextGroup;
(function (TextGroup) {
    TextGroup["HeadingAndIntroduction"] = "HEADING_AND_INTRODUCTION";
    TextGroup["Section"] = "SECTION";
    TextGroup["AreaDescription"] = "AREA_DESCRIPTION";
    TextGroup["POI"] = "POI";
    TextGroup["SpeechMovieText"] = "SPEECH_MOVIE_TEXT";
    TextGroup["Ads"] = "ADS";
    TextGroup["SocialMedia"] = "SOCIAL_MEDIA";
    TextGroup["USP"] = "USP";
    TextGroup["Other"] = "OTHER";
})(TextGroup || (TextGroup = {}));
