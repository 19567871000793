export function mapAddressFromDawaDto({ adresse: { id, vejnavn: street, husnr: houseNumber, etage: floor, dør: appartmentNumber, postnr: postNumber, postnrnavn: city, supplerendebynavn: area, }, tekst: full, }) {
    return {
        id,
        street,
        houseNumber,
        floor,
        appartmentNumber,
        postNumber,
        city,
        full,
        area,
    };
}
export function mapAddressPredictionFromDawaDto(dto) {
    return {
        id: dto.adresse.id,
        text: dto.tekst,
        __internal_address: mapAddressFromDawaDto(dto),
    };
}
export function mapAddressFromGoogleDto({ place_id, formatted_address, address_components, }) {
    var _a, _b;
    const street = address_components.find(({ types }) => types.includes('route'));
    const houseNumber = address_components.find(({ types }) => types.includes('street_number'));
    const postNumber = address_components.find(({ types }) => types.includes('postal_code'));
    const city = (_b = (_a = address_components.find(({ types }) => types.includes('sublocality'))) !== null && _a !== void 0 ? _a : address_components.find(({ types }) => types.includes('postal_town'))) !== null && _b !== void 0 ? _b : address_components.find(({ types }) => types.includes('locality'));
    return street && houseNumber && postNumber && city
        ? {
            id: place_id,
            full: formatted_address,
            street: street.long_name,
            houseNumber: houseNumber.long_name,
            postNumber: postNumber.long_name,
            city: city.long_name,
        }
        : undefined;
}
export function mapAddressPredictionFromGoogleDto({ place_id, description, types, }) {
    return types.includes('street_address') || types.includes('premise')
        ? {
            id: place_id,
            text: description,
        }
        : undefined;
}
export function mapFullAddressFromDto(dto) {
    let output = '';
    if (dto.street) {
        output += `${dto.street} `;
    }
    if (dto.buildingNumber) {
        output += `${dto.buildingNumber}, `;
    }
    if (dto.buildingFloor) {
        output += `${dto.buildingFloor}, `;
    }
    if (dto.buildingDoor) {
        output += `${dto.buildingDoor}, `;
    }
    if (dto.cityArea) {
        output += `${dto.cityArea}, `;
    }
    if (dto.zip) {
        output += `${dto.zip} `;
    }
    if (dto.city) {
        output += `${dto.city} `;
    }
    if (dto.country) {
        output += `${dto.country}`;
    }
    return output.trim();
}
