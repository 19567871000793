import http, { endpoints } from '../http';
import { mapUserToDto, getInitialsFromFullName, mapUserBranchFromDto } from './helpers';
import { mapBranchFromDto } from '../department/helpers';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class UsersApi {
    static async getUsersForBranch(branchId) {
        const response = await datahubClient.get(`/User/by-branchId/${branchId}/active`);
        return (response === null || response === void 0 ? void 0 : response.data) ? response === null || response === void 0 ? void 0 : response.data.map(user => mapUserBranchFromDto(user, branchId)) : [];
    }
    static async getUserById(id) {
        const response = await datahubClient.get(`/User/id/${id}`);
        const fullName = (response === null || response === void 0 ? void 0 : response.data) ? `${response.data.firstName} ${response.data.lastName}` : '';
        return ((response === null || response === void 0 ? void 0 : response.data) && {
            id: String(response.data.id),
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            phoneNumber: response.data.phone,
            fullName,
            initials: fullName ? getInitialsFromFullName(fullName) : '',
            email: response.data.email,
            isInternalUser: true,
            branches: response.data.branches.map(mapBranchFromDto),
            visiblePrices: response.data.settings.showPrices,
        });
    }
    static async updateUser(user) {
        const response = await datahubClient.patch(`/User/id/${user.id}`, mapUserToDto(user));
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getContactPersonTypes() {
        const response = await datahubClient.get('/orders/contact-person-types');
        return (response === null || response === void 0 ? void 0 : response.data) ? response.data : [];
    }
    static async getPendingInvitesForBranch(branchId) {
        const response = await datahubClient.get(`/User/Invitation/branch/${branchId}`);
        return (response === null || response === void 0 ? void 0 : response.data) || [];
    }
    static async sendInvite(params) {
        const response = await datahubClient.post('/User/Invitation', params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async removePendingInvite(inviteId) {
        const response = await datahubClient.delete(`/User/Invitation/${inviteId}`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async resendInvite(inviteId) {
        const response = await datahubClient.post(`/User/Invitation/${inviteId}/resend`);
        return (response === null || response === void 0 ? void 0 : response.data) || {};
    }
    static async findInviteById(inviteId) {
        const response = await datahubClient.get(`/User/Invitation/${inviteId}`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async acceptInvite(params) {
        const response = await datahubClient.post(`/User/Invitation/${params.id}/accept`, {
            firstName: params.firstName,
            lastName: params.lastName,
            mobilePhone: params.mobilePhone,
            password: params.password,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async revokeBranchAccessForUser(params) {
        const response = await datahubClient.delete(`/User/id/${params.userId}/branch/${params.branchId}`);
        return (response === null || response === void 0 ? void 0 : response.data) || {};
    }
    static async updateBranchAccessForUser(params) {
        const response = await datahubClient.patch(`/User/id/${params.userId}/branch/${params.branchId}`, {
            isAdmin: params.isAdmin,
        });
        return (response === null || response === void 0 ? void 0 : response.data) || {};
    }
}
