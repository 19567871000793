/* eslint @typescript-eslint/no-explicit-any: 0 */
import i18n from 'i18next';
import * as yup from 'yup';
const rules = {
    mixed: ['default', 'required', 'oneOf', 'notOneOf', 'notType', 'defined'],
    string: ['length', 'min', 'max', 'matches', 'email', 'url', 'uuid', 'trim', 'lowercase', 'uppercase'],
    number: ['min', 'max', 'lessThan', 'moreThan', 'positive', 'negative', 'integer'],
    date: ['min', 'max'],
    boolean: ['isValue'],
    object: ['noUnknown'],
    array: ['min', 'max', 'length'],
};
yup.setLocale(Object.keys(rules).reduce((acc, type) => ({
    ...acc,
    [type]: rules[type].reduce((typeAcc, rule) => ({
        ...typeAcc,
        [rule]: (params) => i18n.t(`validation.${type}.${rule}`, params),
    }), {}),
}), {}));
