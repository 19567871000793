/* eslint @typescript-eslint/no-explicit-any: 0 */
import styled, { ITheme, MarginMixinParamType, PaddingMixinParamType } from 'styled-components'
import { margin, padding } from '@libs/theme/mixins'
import { ContainerSizeType, ContainerSpacingType, IContainerConfig } from './types'
import camelCase from 'lodash/camelCase'

function withImportantKeyword(style: string, enabled: boolean) {
	return enabled ? `${style} !important` : style
}

function getMarginStyle(
	theme: ITheme,
	margins: ContainerSpacingType<MarginMixinParamType> | undefined,
	important = false
) {
	if (margins === undefined) {
		return {}
	} else if (typeof margins === 'string' || typeof margins === 'number') {
		return {
			margin: margin(margins)({ theme }),
		}
	} else if (margins instanceof Array) {
		return {
			margin: margin(...margins)({ theme }),
		}
	} else {
		const { top, right, bottom, left } = margins
		return {
			marginTop: top !== undefined ? withImportantKeyword(margin(top)({ theme }), important) : undefined,
			marginRight: right !== undefined ? withImportantKeyword(margin(right)({ theme }), important) : undefined,
			marginBottom: bottom !== undefined ? withImportantKeyword(margin(bottom)({ theme }), important) : undefined,
			marginLeft: left !== undefined ? withImportantKeyword(margin(left)({ theme }), important) : undefined,
		}
	}
}

function getPaddingStyle(theme: ITheme, paddings: ContainerSpacingType<PaddingMixinParamType> | undefined) {
	if (paddings === undefined) {
		return {}
	} else if (typeof paddings === 'string' || typeof paddings === 'number') {
		return {
			padding: padding(paddings)({ theme }),
		}
	} else if (paddings instanceof Array) {
		return {
			padding: padding(...paddings)({ theme }),
		}
	} else {
		const { top, right, bottom, left } = paddings
		return {
			paddingTop: top !== undefined ? padding(top)({ theme }) : undefined,
			paddingRight: right !== undefined ? padding(right)({ theme }) : undefined,
			paddingBottom: bottom !== undefined ? padding(bottom)({ theme }) : undefined,
			paddingLeft: left !== undefined ? padding(left)({ theme }) : undefined,
		}
	}
}

function getFillStyle(fill: IContainerConfig['fill']) {
	if (fill === 'auto') {
		return {
			width: 'auto',
			height: 'auto',
		}
	} else if (typeof fill === 'number') {
		return {
			width: `${fill * 100}%`,
			height: `${fill * 100}%`,
		}
	} else if (typeof fill === 'boolean') {
		return {
			width: fill ? '100%' : 'auto',
			height: fill ? '100%' : 'auto',
		}
	} else if (fill instanceof Array) {
		return {
			width: fill[0] === 'auto' ? 'auto' : `${fill[0] * 100}%`,
			height: fill[1] === 'auto' ? 'auto' : `${fill[1] * 100}%`,
		}
	} else {
		return {}
	}
}

function getSpaceStyle(
	theme: ITheme,
	space: IContainerConfig['space'],
	spaceDirection: IContainerConfig['spaceDirection'] = 'horizontal'
): Record<string, any> {
	if (space) {
		return spaceDirection === 'horizontal'
			? {
					'> *': getMarginStyle(theme, { left: 0, right: space }, true),
					'> *:last-child': getMarginStyle(theme, { right: 0 }, true),
			  }
			: {
					'> *': getMarginStyle(theme, { top: 0, bottom: space }, true),
					'> *:last-child': getMarginStyle(theme, { bottom: 0 }, true),
			  }
	} else {
		return {}
	}
}

function getSizeStyle(key: 'min' | 'max', size: ContainerSizeType | undefined) {
	return Object.keys(size || {}).reduce(
		(acc, dimension) => ({
			...acc,
			[camelCase(`${key}-${dimension}`)]: size?.[dimension as keyof ContainerSizeType],
		}),
		{}
	)
}

const Wrapper = styled.div<{ $config: IContainerConfig }>(
	({
		theme,
		$config: {
			margin: marginValue,
			padding: paddingValue,
			space: spaceValue,
			spaceDirection: spaceDirectionValue,
			fill: fillValue,
			min,
			max,
			...cssProps
		},
	}) => ({
		...cssProps,
		...getMarginStyle(theme, marginValue),
		...getPaddingStyle(theme, paddingValue),
		...getFillStyle(fillValue),
		...getSpaceStyle(theme, spaceValue, spaceDirectionValue),
		...getSizeStyle('min', min),
		...getSizeStyle('max', max),
	})
)

export default {
	Wrapper,
}
