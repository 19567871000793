export var ProductType;
(function (ProductType) {
    ProductType["Generic"] = "GENERIC";
    ProductType["Single"] = "SINGLE";
    ProductType["Additional"] = "ADDITIONAL";
})(ProductType || (ProductType = {}));
export var ProductCategory;
(function (ProductCategory) {
    ProductCategory["Case"] = "CASE";
    ProductCategory["Portrait"] = "PORTRAIT";
    ProductCategory["Shop"] = "SHOP";
    ProductCategory["Location"] = "LOCATION";
    ProductCategory["EndUserFee"] = "ENDUSERFEE";
    ProductCategory["None"] = "NONE";
})(ProductCategory || (ProductCategory = {}));
