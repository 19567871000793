/* eslint @typescript-eslint/no-explicit-any: 0 */
import { css, } from 'styled-components';
import tinycolor from 'tinycolor2';
function wrapCss(fn) {
    return ((...args) => fn(css(...args)));
}
export function breakpoint(breakpointIdentifier, delta = 0.0) {
    return ({ theme }) => `${theme.breakpoints[breakpointIdentifier] + delta}px`;
}
export const screen = {
    lte: {
        xxs: wrapCss(content => css `
				@media screen and (max-width: ${breakpoint('xs', -1.0)}) {
					${content}
				}
			`),
        xs: wrapCss(content => css `
				@media screen and (max-width: ${breakpoint('sm', -1.0)}) {
					${content}
				}
			`),
        sm: wrapCss(content => css `
				@media screen and (max-width: ${breakpoint('md', -1.0)}) {
					${content}
				}
			`),
        md: wrapCss(content => css `
				@media screen and (max-width: ${breakpoint('lg', -1.0)}) {
					${content}
				}
			`),
        lg: wrapCss(content => css `
				@media screen and (max-width: ${breakpoint('xl', -1.0)}) {
					${content}
				}
			`),
        xl: wrapCss(content => css `
				@media screen and (max-width: ${breakpoint('xxl', -1.0)}) {
					${content}
				}
			`),
    },
    gte: {
        xs: wrapCss(content => css `
				@media screen and (min-width: ${breakpoint('xs')}) {
					${content}
				}
			`),
        sm: wrapCss(content => css `
				@media screen and (min-width: ${breakpoint('sm')}) {
					${content}
				}
			`),
        md: wrapCss(content => css `
				@media screen and (min-width: ${breakpoint('md')}) {
					${content}
				}
			`),
        lg: wrapCss(content => css `
				@media screen and (min-width: ${breakpoint('lg')}) {
					${content}
				}
			`),
        xl: wrapCss(content => css `
				@media screen and (min-width: ${breakpoint('xl')}) {
					${content}
				}
			`),
        xxl: wrapCss(content => css `
				@media screen and (min-width: ${breakpoint('xxl')}) {
					${content}
				}
			`),
    },
};
export function color(colorIdentifier) {
    return ({ theme }) => {
        if (typeof colorIdentifier === 'string') {
            if (colorIdentifier === 'white') {
                return theme.colors.gray['000'];
            }
            else if (colorIdentifier === 'black') {
                return theme.colors.gray['900'];
            }
            else {
                return theme.colors[colorIdentifier]['500'];
            }
        }
        else {
            return theme.colors[colorIdentifier[0]][colorIdentifier[1]];
        }
    };
}
export function rgbaColor(colorIdentifier, alpha = 1.0) {
    return (props) => {
        const colorValueFn = color(colorIdentifier);
        const colorObj = tinycolor(colorValueFn(props));
        colorObj.setAlpha(alpha);
        return colorObj.toRgbString();
    };
}
export function fontFamily() {
    return ({ theme }) => theme.typography.family;
}
export function fontSize(scaleIdentifier) {
    return ({ theme }) => `${theme.typography.sizes[scaleIdentifier]}px`;
}
export function lineHeight(multiplier = 1.0) {
    return ({ theme }) => theme.typography.lineHeightBase * multiplier;
}
export function typography(scaleIdentifier, lineHeightMultiplier = 1.0) {
    return css `
		font-family: ${fontFamily()};
		font-size: ${fontSize(scaleIdentifier)};
		line-height: ${lineHeight(lineHeightMultiplier)};
	`;
}
export function height(scaleIdentifier) {
    return ({ theme }) => `${theme.heights[scaleIdentifier]}px`;
}
export function margin(...params) {
    return ({ theme }) => params
        .slice()
        .map(param => typeof param === 'number' || param === 'auto' ? param : `${theme.margins[param]}px`)
        .join(' ');
}
export function padding(...params) {
    return ({ theme }) => params
        .slice()
        .map(param => (typeof param === 'number' ? param : `${theme.paddings[param]}px`))
        .join(' ');
}
export function borderRadius(scaleIdentifier) {
    return ({ theme }) => `${theme.radii[scaleIdentifier]}px`;
}
export function easing(easingIdentifier) {
    return ({ theme }) => `${theme.easing[easingIdentifier]}`;
}
export function shadow(shadowIdentifier) {
    return ({ theme }) => `${theme.shadows[shadowIdentifier]}`;
}
export function scrollbar(visible = true, size = 8, horizontal = false) {
    return css `
		&::-webkit-scrollbar {
			${horizontal
        ? css `
						height: ${visible ? size : 0}px;
				  `
        : css `
						width: ${visible ? size : 0}px;
				  `}
			background-color: ${visible ? color(['gray', '300']) : 'transparent'};
		}
		&::-webkit-scrollbar-thumb {
			background-color: ${visible ? color(['gray', '700']) : 'transparent'};
			border-radius: ${borderRadius('sm')};
		}
	`;
}
