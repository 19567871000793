import styled from 'styled-components';
import { Typography } from 'antd';
import { color, fontSize, margin } from '@libs/theme/mixins';
const Description = styled(Typography.Text) `
	font-size: ${fontSize('md')};
	color: ${color(['gray', '700'])};
	margin-top: ${margin('xxs')};
`;
export default {
    Description,
};
