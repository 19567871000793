import React, { useMemo } from 'react';
import { useLocalization } from '@libs/localization';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import daDK from 'antd/lib/locale/da_DK';
import svSE from 'antd/lib/locale/sv_SE';
import nbNO from 'antd/lib/locale/nb_NO';
import merge from 'lodash/merge';
const AntdConfigProvider = props => {
    const { locale: currentLocale, t } = useLocalization();
    const locale = useMemo(() => {
        let antdLocale = {
            en: enUS,
            da: daDK,
            sv: svSE,
            no: nbNO,
        }[currentLocale] || enUS;
        antdLocale = merge(antdLocale, {
            Table: {
                triggerAsc: t('common.actions.sortAscending'),
                triggerDesc: t('common.actions.sortDescending'),
                cancelSort: t('common.actions.cancelSorting'),
            },
        });
        return antdLocale;
    }, [currentLocale]);
    return React.createElement(ConfigProvider, { locale: locale }, props.children);
};
export default AntdConfigProvider;
