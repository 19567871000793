import tinycolor from 'tinycolor2';
// Reference to:
// https://github.com/ant-design/ant-design/blob/master/components/style/color/colorPalette.less
const HUE_STEP = 2;
const SATURATION_STEP_1 = 0.16;
const SATURATION_STEP_2 = 0.05;
const BRIGHTNESS_STEP_1 = 0.05;
const BRIGHTNESS_STEP_2 = 0.15;
const LIGHT_COLOR_COUNT = 5;
const DARK_COLOR_COUNT = 4;
const TINTS = ['000', '100', '200', '300', '400', '500', '600', '700', '800', '900'];
function getHue(hsv, i, isLight) {
    let hue;
    if (hsv.h >= 60 && hsv.h <= 240) {
        hue = isLight ? hsv.h - HUE_STEP * i : hsv.h + HUE_STEP * i;
    }
    else {
        hue = isLight ? hsv.h + HUE_STEP * i : hsv.h - HUE_STEP * i;
    }
    if (hue < 0) {
        hue += 360;
    }
    else if (hue >= 360) {
        hue -= 360;
    }
    return Math.round(hue);
}
function getSaturation(hsv, i, isLight) {
    let saturation;
    if (isLight) {
        saturation = hsv.s - SATURATION_STEP_1 * i;
    }
    else if (i === DARK_COLOR_COUNT) {
        saturation = hsv.s + SATURATION_STEP_1;
    }
    else {
        saturation = hsv.s + SATURATION_STEP_2 * i;
    }
    if (saturation > 1) {
        saturation = 1;
    }
    if (isLight && i === LIGHT_COLOR_COUNT && saturation > 0.1) {
        saturation = 0.1;
    }
    if (saturation < 0.06) {
        saturation = 0.06;
    }
    return Number(saturation.toFixed(2));
}
function getValue(hsv, i, isLight) {
    let value;
    if (isLight) {
        value = hsv.v + BRIGHTNESS_STEP_1 * i;
    }
    else {
        value = hsv.v - BRIGHTNESS_STEP_2 * i;
    }
    if (value > 1) {
        value = 1;
    }
    return Number(value.toFixed(2));
}
function getAntdPaletteColor(baseColor, index) {
    const isLight = index <= 6;
    const hsv = tinycolor(baseColor).toHsv();
    const i = isLight ? LIGHT_COLOR_COUNT + 1 - index : index - LIGHT_COLOR_COUNT - 1;
    return tinycolor({
        h: getHue(hsv, i, isLight),
        s: getSaturation(hsv, i, isLight),
        v: getValue(hsv, i, isLight),
    }).toHexString();
}
export function createPalette(baseColor, overwrites) {
    return TINTS.reduce((acc, tint) => {
        var _a;
        return ({
            ...acc,
            [tint]: (_a = acc[tint]) !== null && _a !== void 0 ? _a : getAntdPaletteColor(baseColor, TINTS.indexOf(tint) + 1),
        });
    }, {
        ...(overwrites || {}),
        '500': baseColor,
    });
}
