import { color, easing, padding } from '@libs/theme/mixins';
import styled from 'styled-components';
import IconButton from '../IconButton';
const Wrapper = styled.div `
	position: relative;
	padding-bottom: ${({ $disabled }) => ($disabled ? 0 : padding('xxl'))};
`;
const Content = styled.div `
	position: relative;
	z-index: 0;
	border-bottom: ${({ $disabled }) => ($disabled ? 'none' : '1px solid')};
	border-bottom-color: ${color(['primary', '200'])};
	padding-bottom: ${({ $disabled }) => ($disabled ? 0 : padding('xxl'))};
`;
const ContentInner = styled.div `
	max-height: ${({ $disabled, $expanded, $maxHeight }) => ($disabled ? 'auto' : $expanded ? `${$maxHeight}px` : 0)};
	overflow: hidden;
	transition-property: ${({ $disabled }) => ($disabled ? 'none' : 'max-height')};
	transition-duration: 500ms;
	transition-timing-function: ${easing('inOut')};
`;
const ExpandButton = styled(IconButton) `
	position: absolute !important;
	bottom: ${padding('xxl')};
	left: 50%;
	transform: translate(-50%, 50%);
	z-index: 1;
`;
export default {
    Wrapper,
    Content,
    ContentInner,
    ExpandButton,
};
