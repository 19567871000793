export var CaseCategory;
(function (CaseCategory) {
    CaseCategory["Case"] = "CASE";
    CaseCategory["Location"] = "LOCATION";
    CaseCategory["Portrait"] = "PORTRAIT";
    CaseCategory["Group"] = "GROUP";
    CaseCategory["IntroOutro"] = "INTRO_OUTRO";
    CaseCategory["Shop"] = "SHOP";
    CaseCategory["Matterport"] = "MATTERPORT";
    CaseCategory["BeforeAfter"] = "BEFORE_AFTER";
})(CaseCategory || (CaseCategory = {}));
export var CaseMaterialStatus;
(function (CaseMaterialStatus) {
    CaseMaterialStatus["ACTIVE"] = "ACTIVE";
    CaseMaterialStatus["ARCHIVING"] = "ARCHIVING";
    CaseMaterialStatus["RESTORING"] = "RESTORING";
    CaseMaterialStatus["ARCHIVED"] = "ARCHIVED";
})(CaseMaterialStatus || (CaseMaterialStatus = {}));
