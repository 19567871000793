import styled, { css } from 'styled-components';
import { Skeleton as AntdSkeleton } from 'antd';
const ParagraphWrapper = styled.div `
	${({ singleRow }) => singleRow &&
    css `
			.ant-skeleton {
				& ul {
					display: none;
				}
			}
		`}

	.ant-skeleton-paragraph {
		& li:last-child {
			display: none;
		}
	}
`;
const ButtonWrapper = styled.span `
	max-width: 100%;
	display: inline-block;

	.ant-skeleton-button {
		${({ width }) => width && `width: ${width}px;`}
		${({ height }) => height && `height: ${height}px;`}
		max-width: 100%;
	}
`;
const ImageSkeleton = styled(AntdSkeleton.Image) `
	max-width: 100%;
	display: inline-block;

	${({ width, height }) => (width || height) &&
    `
          	.ant-skeleton-image {
							${width && `width: ${width}px;`}
							${height && `height: ${height}px;`}
							max-width: 100%;
						}
          `}

	${({ fill }) => fill &&
    `
			display: flex;
			justify-content: center;
			align-items: center;
				
			width: 100%;
			height: 100%;
			
			background: rgba(190, 190, 190, 0.2);
			
			.ant-skeleton-image {
				background: none;
			}
  `}
`;
export default {
    ParagraphWrapper,
    ButtonWrapper,
    ImageSkeleton,
};
