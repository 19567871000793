/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { useCallback } from 'react';
import { Spin } from 'antd';
import { Select } from 'formik-antd';
import { useFormikContext } from 'formik';
import Container from '../Container';
import { ANT_SELECT_HIDDEN_DROPDOWN_IF_EMPTY_CLASS_NAME } from '@libs/theme';
import debounce from 'lodash/debounce';
/*
Why do we need this?
:: Ant's autocomplete component is meant to be a text input which "predicts" and offers suggestions to fill in the text. Is is
not a component where the user is required to the the action of selecting an item but rather he has the option to do so.

:: Considering our use case of address autocomplete we actually do want the user to select an item from the dropdown
otherwise we would not be able to properly validate his input via formik and show and error in case he did not

:: Based on this thread here
https://github.com/ant-design/ant-design/issues/21882
it is indeed recommended to use a Select instead with some prop tweaking

*/
const SelectAutocomplete = ({ name, loading, onChange, onSearch, ...restProps }) => {
    const formik = useFormikContext();
    const onSelectChange = useCallback((value, option) => {
        if (formik) {
            formik.setFieldValue(name, value);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(value, option);
    }, [name, formik, onChange]);
    const debounceOnChange = debounce(onSelectChange, 250);
    return (React.createElement(Select, Object.assign({ name: name, showSearch: true, showArrow: false, notFoundContent: loading ? (React.createElement(Container, { display: 'flex', justifyContent: 'center' },
            React.createElement(Spin, null))) : undefined, dropdownClassName: !loading ? ANT_SELECT_HIDDEN_DROPDOWN_IF_EMPTY_CLASS_NAME : undefined, filterOption: false, onSearch: typeof onSearch === 'function' ? debounce(onSearch, 250) : undefined, onChange: debounceOnChange, onBlur: formik.handleBlur }, restProps)));
};
export default SelectAutocomplete;
