import React from 'react';
import { Skeleton as AntdSkeleton } from 'antd';
import Button from './button';
import Paragraph from './paragraph';
import Cards from './cards';
const Skeleton = (props) => React.createElement(AntdSkeleton, Object.assign({}, props));
Skeleton.Paragraph = Paragraph;
Skeleton.Button = Button;
Skeleton.Cards = Cards;
Skeleton.Image = AntdSkeleton.Image;
Skeleton.Avatar = AntdSkeleton.Avatar;
Skeleton.Input = AntdSkeleton.Input;
export default Skeleton;
