import { Component } from 'react';
export default class ModalHost extends Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    render() {
        return this.props.renderFn(this.state);
    }
}
