import React, { createContext, useCallback, useContext, useRef } from 'react';
import ModalHost from './host';
const ModalContext = createContext(() => {
    /* */
});
function useModalManager() {
    const setState = useContext(ModalContext);
    const show = (id, params) => setState({ id, params: params !== null && params !== void 0 ? params : {} });
    const hide = () => setState({
        id: undefined,
        params: undefined,
    });
    return { show, hide };
}
export function useHideModal() {
    return useModalManager().hide;
}
export function createModalManager(renderFn) {
    const Provider = ({ children }) => {
        const ref = useRef(null);
        const update = useCallback((state) => {
            if (ref.current) {
                ref.current.setState(state);
            }
        }, []);
        return (React.createElement(ModalContext.Provider, { value: update },
            React.createElement(ModalHost, { ref: ref, renderFn: renderFn }),
            children));
    };
    function useShowModal() {
        const show = useModalManager().show;
        return (id, params) => show(id, params);
    }
    return { Provider, useShowModal };
}
