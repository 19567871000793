import styled from 'styled-components';
import { Search16, CloseFilled16 } from '@carbon/icons-react';
import { rgbaColor } from '@libs/theme/mixins';
const SearchIconSuffix = styled(Search16) `
	color: ${rgbaColor('black', 0.25)};
`;
const ClearTextSuffix = styled(CloseFilled16) `
	cursor: pointer;
	color: ${rgbaColor('black', 0.25)};

	&:hover {
		color: ${rgbaColor('black', 0.45)};
	}
`;
export default {
    ClearTextSuffix,
    SearchIconSuffix,
};
