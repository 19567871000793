import { v4 as uuidv4 } from 'uuid';
import { LocalizedError } from '@libs/localization';
export function getUniqueS3DocumentPath({ caseId, branchId, extension }) {
    if (!caseId)
        throw new LocalizedError('materials.messages.caseIdMissing');
    if (!branchId)
        throw new LocalizedError('materials.messages.clientIdMissing');
    return `documents/${branchId}/${caseId}/${uuidv4()}.${extension}`;
}
export function getS3TaskUploadTempPath({ task: { branchId, orderlineId }, fileUuid, fileExtension, }) {
    return `/tmp/${branchId}/${orderlineId}/${fileUuid}.${fileExtension}`;
}
export function getS3Path({ outerDir, innerDir, fileUuid, fileExtension, }) {
    return `/tmp/${outerDir}/${innerDir}/${fileUuid}.${fileExtension}`;
}
