import styled, { css } from 'styled-components';
import { Alert } from 'antd';
import React from 'react';
import { color, height, padding, screen } from '@libs/theme/mixins';
const Wrapper = styled.div `
	&,
	> * {
		min-height: calc(100vh - ${height('xxl')});

		${screen.lte.xs `
			min-height: calc(100vh - ${height('xl')});
		`}
	}

	.ant-affix {
		z-index: 998;
	}
`;
const Container = styled.div `
	min-height: ${({ $type }) => ($type === 'content' ? css `calc(100vh - ${height('xxl')})` : '100%')};
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	overflow-y: ${({ $type }) => ($type === 'content' ? 'auto' : 'hidden')};
	background: ${({ $type }) => ($type === 'content' ? color(['primary', '800']) : 'transparent')};

	> * {
		width: 100%;
		max-width: 1920px;
		padding: 0 8.33%;

		${screen.lte.xs `
			padding: ${padding(0, 'md')};
		`}
	}
`;
const SystemAlert = styled(props => React.createElement(Alert, Object.assign({}, props))) `
	padding: ${padding('xs')} 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.ant-alert-icon {
		padding-left: 8.33%;
	}

	.ant-alert-content {
		max-width: calc(1920px - 30px - 8.33%);
	}
`;
export default {
    Container,
    Wrapper,
    SystemAlert,
};
