import React, { forwardRef, useMemo, useRef, useState } from 'react';
import Container from '../Container';
import Step from './Step';
import Styled from './Styled';
import { Steps } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { useScreenMatch } from '@libs/theme';
function getStepElementHeight(parentEl, selector = '') {
    var _a;
    return (_a = parentEl === null || parentEl === void 0 ? void 0 : parentEl.querySelector(`:scope >div >div${selector}`)) === null || _a === void 0 ? void 0 : _a.clientHeight;
}
function getStepDeltaHeight(parentEl) {
    var _a;
    const [stepA, stepB] = Array.from((_a = parentEl === null || parentEl === void 0 ? void 0 : parentEl.querySelectorAll(':scope >div >div')) !== null && _a !== void 0 ? _a : []);
    return stepA && stepB ? Math.abs(stepA.clientHeight - stepB.clientHeight) : undefined;
}
const _Stepper = forwardRef(({ current, transitionDuration = 1000, children, onTransitionStart, onTransitionComplete, onStepChange, withAnimation, }, ref) => {
    const prevStepRef = useRef(undefined);
    const contentRef = useRef(null);
    const match = useScreenMatch();
    const [contentTransitionGroupDeltaHeight, setContentTransitionGroupDeltaHeight] = useState();
    const [contentTransitionGroupHeight, setContentTransitionGroupHeight] = useState(undefined);
    const steps = useMemo(() => React.Children.toArray(children)
        .filter(child => child && typeof child === 'object')
        .map(child => child.props), [children]);
    return (React.createElement(Container, { ref: ref, display: 'flex', flexDirection: 'column', alignItems: 'stretch', space: match.lte('sm') ? 'sm' : 'lg', spaceDirection: 'vertical' },
        React.createElement(Container, { display: 'flex', justifyContent: 'center' },
            React.createElement(Styled.Steps, { current: current, onChange: onStepChange }, steps.map((step, index) => (React.createElement(Steps.Step, { key: index, title: step.title }))))),
        React.createElement(Styled.CurrentStepWrapper, { ref: contentRef },
            React.createElement(Styled.ContentTransitionGroup, { "$transitionTimeout": transitionDuration, "$reverse": typeof prevStepRef.current === 'number' && current < prevStepRef.current, "$deltaHeight": contentTransitionGroupDeltaHeight, "$height": contentTransitionGroupHeight, "$withAnimation": !!withAnimation },
                React.createElement(CSSTransition, { key: current, timeout: transitionDuration, onEnter: () => {
                        setContentTransitionGroupDeltaHeight(getStepDeltaHeight(contentRef.current));
                        setContentTransitionGroupHeight(getStepElementHeight(contentRef.current, '.transition-enter'));
                        onTransitionStart === null || onTransitionStart === void 0 ? void 0 : onTransitionStart();
                    }, onEntered: () => {
                        prevStepRef.current = current;
                        setContentTransitionGroupDeltaHeight(getStepDeltaHeight(contentRef.current));
                        setContentTransitionGroupHeight(getStepElementHeight(contentRef.current, '.transition-enter-done'));
                        onTransitionComplete === null || onTransitionComplete === void 0 ? void 0 : onTransitionComplete();
                    }, classNames: "transition" }, steps[current].children)))));
});
const Stepper = _Stepper;
Stepper.Step = Step;
export default Stepper;
