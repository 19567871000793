export var MaterialOrientation;
(function (MaterialOrientation) {
    MaterialOrientation["Horizontal"] = "HORIZONTAL";
    MaterialOrientation["Vertical"] = "VERTICAL";
})(MaterialOrientation || (MaterialOrientation = {}));
// these translated values are hardcoded on BE to differentiate documents from links
export var DocumentTypesKeys;
(function (DocumentTypesKeys) {
    DocumentTypesKeys["ConditionReport"] = "Tilstandsrapport";
    DocumentTypesKeys["ElectricalInstallationReport"] = "Elinstallationsrapport";
    DocumentTypesKeys["EnergyLabeling"] = "Energim\u00E6rkning";
    DocumentTypesKeys["Miscellaneous"] = "Diverse";
    DocumentTypesKeys["ProjectSalesProspectus"] = "Projektsalgsprospekt";
    DocumentTypesKeys["BusinessProspectus"] = "Erhvervsprospekt";
})(DocumentTypesKeys || (DocumentTypesKeys = {}));
export var MaterialTagKeys;
(function (MaterialTagKeys) {
    MaterialTagKeys["someFeed"] = "someFeed";
    MaterialTagKeys["someStory"] = "someStory";
    MaterialTagKeys["video"] = "video";
    MaterialTagKeys["panorama"] = "panorama";
    MaterialTagKeys["slider"] = "slider";
    MaterialTagKeys["virtualTour"] = "virtualTour";
})(MaterialTagKeys || (MaterialTagKeys = {}));
