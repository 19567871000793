import React, { useMemo } from 'react';
import { createGlobalStyle, ThemeProvider as SCThemeProvider } from 'styled-components';
import defaultTheme from './theme';
import antdStyleOverwrites from './styles/antd';
import phoneInputStyleOverwrites from './styles/phone-input';
import animationStyles from './styles/animations';
import { color } from './mixins';
const GlobalStyles = createGlobalStyle `
	html, body, #__next {
		min-height: 100%;
	}

	a, a:hover {
		color: ${color('primary')};
	}

	::selection {
		background: ${color('primary')};
	}

	${antdStyleOverwrites}
	${phoneInputStyleOverwrites}
	${animationStyles}
`;
const ThemeProvider = ({ children, primaryColor, contrastColor, pageBackgroundColor }) => {
    const theme = useMemo(() => ({
        ...defaultTheme,
        colors: {
            ...defaultTheme.colors,
            primary: {
                ...defaultTheme.colors.primary,
                '500': primaryColor || defaultTheme.colors.primary['500'],
                '800': pageBackgroundColor || defaultTheme.colors.primary['800'],
                '900': contrastColor || defaultTheme.colors.primary['900'],
            },
        },
    }), [contrastColor, primaryColor, pageBackgroundColor]);
    return (React.createElement(SCThemeProvider, { theme: theme },
        React.createElement(GlobalStyles, null),
        children));
};
export default ThemeProvider;
