import { UserAccess } from '../users/models';
export function mapBranchFromDto(branchDto) {
    const { id, axCustomerId, name, permissions, country, settings, IsoCode, contactPersonId } = branchDto;
    return {
        id,
        axCustomerId,
        name,
        access: (permissions === null || permissions === void 0 ? void 0 : permissions.admin) ? UserAccess.Admin : UserAccess.Regular,
        countryCode: country,
        useUpperCase: settings === null || settings === void 0 ? void 0 : settings.enforceUpperCaseReference,
        endUserInvoicing: settings === null || settings === void 0 ? void 0 : settings.endUserInvoicing,
        businessInvoicing: settings === null || settings === void 0 ? void 0 : settings.businessInvoicing,
        maxImageUploaded: settings === null || settings === void 0 ? void 0 : settings.maxImageUpload,
        contactPersonId,
        locale: IsoCode,
        _dto: branchDto,
    };
}
export function mapBranchToDto({ name, access, locale, _dto }) {
    return {
        ..._dto,
        name,
        IsoCode: locale,
        permissions: {
            admin: access === UserAccess.Admin,
        },
    };
}
export function mapWhitelabelSettingsFromDto({ logoUrl, primaryColor, primaryColorComplementaryTextColor, pageBackgroundColor, faviconUrl, }) {
    return {
        logoUrl,
        primaryColor,
        contrastColor: primaryColorComplementaryTextColor,
        pageBackgroundColor,
        faviconUrl,
    };
}
