import React, { useState } from 'react';
import { useHideModal } from '../context';
import { Modal, Button, Typography } from 'antd';
import { useDebounce } from 'use-debounce';
import { useLocalization } from '@libs/localization';
import { Close24 } from '@carbon/icons-react';
const ConfirmModal = props => {
    const { t } = useLocalization();
    const { title, confirmLabel = t('common.actions.confirm'), onConfirm, onCancel, message, rejectNode, isDanger = true, } = props;
    const hideModal = useHideModal();
    const [isLoading, setIsLoading] = useState(false);
    const onClose = () => {
        hideModal();
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
    };
    const [isStillLoading] = useDebounce(isLoading, 500);
    return (React.createElement(Modal, { title: title, visible: true, closeIcon: React.createElement(Close24, null), onCancel: onClose, footer: [
            React.createElement(Button, Object.assign({ key: "confirm" }, (isDanger ? { danger: true } : { type: 'primary' }), { loading: isStillLoading, onClick: async () => {
                    setIsLoading(true);
                    await (onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm());
                    setIsLoading(false);
                } }), confirmLabel),
            rejectNode,
        ] },
        React.createElement(Typography.Paragraph, { style: { whiteSpace: 'break-spaces' } }, message)));
};
export default ConfirmModal;
