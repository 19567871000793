import { color, fontSize, padding, screen } from '@libs/theme/mixins';
import styled from 'styled-components';
const Wrapper = styled.div `
	display: flex;
	align-items: center;
	max-width: 100%;

	.ant-select {
		font-size: ${fontSize('xl')};
		font-weight: 500;
		color: ${color('primary')};
		min-width: ${({ $minSelectWidth }) => $minSelectWidth}px;

		${screen.lte.sm `
			min-width: min(${({ $minSelectWidth }) => $minSelectWidth}px, 100%);
			max-width: 100%;
		`}

		&.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			padding-right: ${padding('md')};
			${screen.lte.sm `
				padding-left: 0;
			`}
		}

		&.ant-select-single.ant-select:not(.ant-select-customize-input) .ant-select-selection-search {
			${screen.lte.sm `
				left: 0;
				right: 37px;
			`}
		}

		&.ant-select-open .ant-select-arrow {
			color: ${color(['gray', '600'])};
		}

		.ant-select-arrow {
			font-size: ${fontSize('md')};
			font-weight: 500;
			color: ${color('primary')};
			width: ${fontSize('md')};
			height: ${fontSize('md')};
			transform: translateY(-3px);
		}
	}

	${screen.lte.sm `
		flex-direction: column;
		align-items: flex-start;
	`}
`;
const Label = styled.label `
	font-size: ${fontSize('xl')} !important;
	font-weight: 500;
	color: ${color('black')};
`;
export default {
    Label,
    Wrapper,
};
