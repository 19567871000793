import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from './format';
import resources from './resources';
import locale from './locale';
import PoeLoader from './poe/loader';
const poe = new PoeLoader(Object.keys(resources), resources);
function getLocaleName(code) {
    var _a;
    const { metadata } = ((_a = i18next.options.resources) !== null && _a !== void 0 ? _a : {})[code].translation;
    return metadata === null || metadata === void 0 ? void 0 : metadata.languageName;
}
export function useLocalization() {
    // This makes sure the component using this hook
    // rerenders when the locale changes
    const { t, i18n } = useTranslation();
    const locales = useMemo(() => { var _a; return Object.keys((_a = i18n.options.resources) !== null && _a !== void 0 ? _a : {}).map(code => ({ code, name: getLocaleName(code) })); }, [i18n.options.resources]);
    // Make sure format function changes with locale
    // so that we trigger memo updates
    const f = useCallback((...args) => format(...args), [locale.getCurrent()]);
    // Load resources from POEditor then force re-render everything
    const [, setDidLoadPoeResources] = useState(false);
    useEffect(() => poe.subscribe(() => setDidLoadPoeResources(true)), []);
    return {
        locale: locale.getCurrent(),
        updateLocale: locale.setCurrent,
        locales,
        t,
        f: f,
    };
}
