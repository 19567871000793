import { borderRadius, color, fontSize, height, margin, padding } from '@libs/theme/mixins';
import styled from 'styled-components';
const DropdownWrapper = styled.div `
	padding: ${padding(0, 'sm')};

	.ant-select-item-option {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: ${padding('xxs', 'sm')};
		padding-bottom: calc(
			${padding('xxs')} + ${margin('xxs')}
		); // incorporate fake margin because rc-list does not work with margins between items
		font-size: ${fontSize('sm')};
		color: ${color('white')};

		&:before {
			display: block;
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: ${margin('xxs')};
			left: 0;
			background-color: ${color('primary')};
			border-radius: ${borderRadius('sm')};
			z-index: 0;
		}

		&:not(.ant-select-item-option-selected):after {
			position: relative;
			z-index: 1;
			display: flex;
			content: ' ';
			width: ${height('xxs')};
			height: ${height('xxs')};
			background: ${color('white')};
			border-color: ${color(['gray', '600'])};
			border-radius: ${borderRadius('sm')};
			flex-grow: 0;
			flex-shrink: 0;
		}

		> * {
			position: relative;
			z-index: 1;
		}
	}

	.ant-select-item-option-selected {
		font-weight: 400;
	}

	.ant-select-item-option-state {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: ${height('xxs')};
		height: ${height('xxs')};
		background: ${color('white')};
		border-color: ${color(['gray', '600'])};
		border-radius: ${borderRadius('sm')};
		flex-grow: 0;
		flex-shrink: 0;

		> span {
			color: ${color('black')};

			> svg {
				width: 80%;
				height: 80%;
			}
		}
	}

	.rc-virtual-list-scrollbar {
		right: -6px !important;
		width: 4px !important;
		background-color: ${color(['gray', '300'])} !important;
	}

	.rc-virtual-list-scrollbar-thumb {
		background-color: ${color(['gray', '700'])};
		border-radius: ${borderRadius('sm')};
	}
`;
const SelectionControlsWrapper = styled.div `
	display: flex;
	flex-direction: row;
	padding: ${padding('sm', 0)};

	> * {
		font-size: ${fontSize('sm')};
	}
`;
export default {
    DropdownWrapper,
    SelectionControlsWrapper,
};
