import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { fontSize, margin } from '@libs/theme/mixins';
import { DownOutlined } from '@ant-design/icons';
const Trigger = styled((props) => React.createElement(Button, Object.assign({}, props))) `
	display: flex;
	align-items: center;
`;
const Arrow = styled(DownOutlined) `
	width: ${fontSize('sm')};
	height: ${fontSize('sm')};
	color: rgba(0, 0, 0, 0.25);
	margin-left: ${margin('xxs')};

	&,
	& > svg {
		font-size: ${fontSize('sm')};
	}
`;
export default {
    Trigger,
    Arrow,
};
