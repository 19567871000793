import http, { endpoints } from '../http';
import { mapBranchFromDto, mapWhitelabelSettingsFromDto } from './helpers';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class DepartmentApi {
    static async searchBranchByKey(params) {
        var _a, _b;
        const response = await datahubClient.post('/Branch/search', params);
        return {
            total: ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.total) || 0,
            results: ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.results.map(mapBranchFromDto)) || [],
        };
    }
    static async getBranchById(branchId) {
        const response = await datahubClient.get(`/Branch/id/${branchId}`);
        return response.data;
    }
    static async getWhitelabelSettings() {
        const response = await datahubClient.get('/webshop/whitelabel');
        return (response === null || response === void 0 ? void 0 : response.data) ? mapWhitelabelSettingsFromDto(response.data) : undefined;
    }
    static async getUserBranches() {
        var _a;
        const response = await datahubClient.get('/Authentication/user/branches');
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.map(mapBranchFromDto);
    }
    static async getCanBranchCreateOrder(branchId) {
        try {
            const response = await datahubClient.post('/branch/ordercreationblacklistings', [branchId]);
            return response == null || response == undefined || response.data == null || response.data == undefined || (Object.keys(response.data).length === 0 && response.data.constructor === Object) || !response.data[branchId];
        }
        catch (error) {
            console.log("Error occurred when calling getCanBranchCreateOrder, for branchId: " + branchId);
            console.error(error);
        }
        return true;
    }
}
