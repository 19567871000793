import { mapTaskCategoryFromDto } from '../tasks/helpers';
import { ProductCategory } from './models';
export function mapProductCategoryFromDto(productCategory) {
    switch (productCategory) {
        case 'CASE':
            return ProductCategory.Case;
        case 'PORTRAIT':
            return ProductCategory.Portrait;
        case 'LOCATION':
            return ProductCategory.Location;
        case 'SHOP':
            return ProductCategory.Shop;
        case 'ENDUSERFEE':
            return ProductCategory.EndUserFee;
        default:
            return ProductCategory.None;
    }
}
export function mapProductFromDto(dto) {
    const { id, name, imageSrc, imageThumbnailSrc, description, price, quantity, currency, unit, maxOrderAmount, maxKvmSize, tasks, showAlert, itemNumber, config, variant, } = dto;
    return {
        _dto: dto,
        id,
        name,
        description,
        quantity,
        thumbnail: imageThumbnailSrc || imageSrc,
        price,
        currency,
        maxOrderAmount,
        maxKvmSize,
        tasks: (tasks === null || tasks === void 0 ? void 0 : tasks.map(({ type }) => ({ type: mapTaskCategoryFromDto(type) }))) || [],
        unit,
        showAlert,
        itemNumber,
        config,
        variant,
    };
}
export function mapPackageFromDto(dto) {
    const { grouping, id, name, imageSrc, imageThumbnailSrc, description, price, currency, products, requiredProductOptions, itemNumber, config, packages, } = dto;
    return {
        _dto: dto,
        id,
        thumbnail: (imageThumbnailSrc === null || imageThumbnailSrc === void 0 ? void 0 : imageThumbnailSrc.includes('http')) ? imageThumbnailSrc : imageSrc,
        name,
        description,
        totalPrice: price || 0,
        basePrice: price || 0,
        currency,
        type: grouping,
        products: (products === null || products === void 0 ? void 0 : products.map(mapProductFromDto)) || [],
        choices: requiredProductOptions === null || requiredProductOptions === void 0 ? void 0 : requiredProductOptions.map(option => {
            var _a;
            return ({
                minPrice: option.minPrice,
                group: option.groupName,
                products: option.products.map(mapProductFromDto),
                packages: (_a = option.packages) === null || _a === void 0 ? void 0 : _a.map(mapPackageFromDto),
            });
        }),
        packages: packages ? packages.map(mapPackageFromDto) : undefined,
        itemNumber,
        config,
    };
}
