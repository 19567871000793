import { createPalette } from './utils';
export const googleFontUri = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap';
const theme = {
    breakpoints: {
        xs: 480,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1600,
    },
    colors: {
        primary: createPalette('#F1554C', {
            '000': '#FFFAF4',
            '100': '#EDE3D9',
            '200': '#E5E5E5',
            '300': '#FFD7AF',
            '800': '#F8F8FA',
            '900': '#FFFFFF', // base contrast
        }),
        gray: {
            '000': '#fff',
            '100': '#fafafa',
            '200': '#f6f6f6',
            '300': '#ececec',
            '400': '#e3e3e3',
            '500': '#d9d9d9',
            '600': '#d3d3d3',
            '700': '#999999',
            '800': '#1D1D1F',
            '900': '#212121',
        },
        info: createPalette('#1890ff'),
        error: createPalette('#ff4d4f'),
        success: createPalette('#52c41a'),
        warning: createPalette('#faad14'),
    },
    typography: {
        family: 'Montserrat, sans-serif',
        sizes: {
            xxs: 10,
            xs: 12,
            sm: 14,
            md: 16,
            lg: 18,
            xl: 20,
            xxl: 22,
        },
        lineHeightBase: 1.5715,
    },
    heights: {
        xxs: 24,
        xs: 35,
        sm: 50,
        md: 60,
        lg: 70,
        xl: 80,
        xxl: 100, // header
    },
    margins: {
        xxs: 10,
        xs: 14,
        sm: 20,
        md: 28,
        lg: 36,
        xl: 50,
        xxl: 80,
    },
    paddings: {
        xxs: 10,
        xs: 14,
        sm: 20,
        md: 28,
        lg: 36,
        xl: 50,
        xxl: 80,
    },
    radii: {
        sm: 4,
        md: 6,
        lg: 40,
    },
    easing: {
        baseIn: 'cubic-bezier(0.9, 0, 0.3, 0.7)',
        baseOut: 'cubic-bezier(0.7, 0.3, 0.1, 1)',
        in: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
        out: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        inOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
        inBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
        outBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
        inOutBack: 'cubic-bezier(0.71, -0.46, 0.29, 1.46)',
        inCirc: 'cubic-bezier(0.6, 0.04, 0.98, 0.34)',
        outCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
        inOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        inQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
        outQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
        inOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
    },
    shadows: {
        box: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        top: '0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03)',
        right: '6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03)',
        bottom: '0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03)',
        left: '-6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03)',
    },
};
export default theme;
