import { Upload } from 'antd';
import styled, { css } from 'styled-components';
import { color, fontSize } from '@libs/theme/mixins';
const Dragger = styled(Upload.Dragger) `
	${({ $hideAnchorIcon }) => $hideAnchorIcon &&
    css `
			& + .ant-upload-list.ant-upload-list-text {
				.ant-upload-text-icon {
					display: none;
				}

				.ant-upload-list-item-name {
					padding-left: 0;
				}

				.ant-upload-list-item-info {
					padding-left: 0;
				}

				.ant-upload-list-item-progress {
					padding-left: 0;
				}
			}
		`}
	
  .ant-upload-hint {
    color: ${color(['gray', '700'])};
    ${({ $hideAnchorIcon }) => !$hideAnchorIcon &&
    css `
				font-size: ${fontSize('sm')};
			`}
`;
export default {
    Dragger,
};
