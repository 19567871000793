import axios from 'axios';
import Cookie from 'js-cookie';
export const IS_ESOFT_USER_KEY = 'esoft.user';
export const HEADER_USER_ID_KEY = 'header.user.id';
export const AUTH_LOGIN_KEY = 'auth.login';
export const AUTH_LOGOUT_KEY = 'auth.logout';
export const AUTH_SCHEMA_KEY = 'auth.schema';
export const AUTH_REFRESHING = 'auth.refreshing';
export const RESET_PASSWORD_CODE_KEY = 'password.error.code';
export const AUTH_RESET_PASSWORD_KEY = 'auth.reset.password';
export const AUTH_REDIRECT_URL = 'auth.redirect.url';
export const MINDWORKING_REDIRECT_REFERENCE = 'mindworking.reference';
const TOKEN_KEY = 'auth.token';
const TOKEN_PERSISTENCE = 365; // days
let errorHandler;
const blacklistedAuthorizationUrls = ['amazonaws.com'];
export const endpoints = {
    datahub: process.env.DATAHUB_API_ENDPOINT || 'https://datahub.stage.efkt.net',
};
function forgetAuthToken() {
    if (typeof window !== undefined) {
        Cookie.remove(TOKEN_KEY);
        const logoutUrl = localStorage.getItem(AUTH_LOGOUT_KEY);
        if (logoutUrl)
            window.location.href = logoutUrl;
    }
}
function getAuthToken() {
    return typeof window !== undefined ? Cookie.get(TOKEN_KEY) : undefined;
}
function setAuthToken(value, persist) {
    Cookie.set(TOKEN_KEY, value, {
        expires: persist ? TOKEN_PERSISTENCE : undefined,
    });
}
function createClient(baseURL, appendAuthorizationHeader = true) {
    const client = axios.create({
        baseURL,
    });
    if (appendAuthorizationHeader) {
        client.interceptors.request.use((requestConfig) => {
            var _a;
            const token = getAuthToken();
            if (token && !blacklistedAuthorizationUrls.some(url => { var _a; return ((_a = requestConfig.url) === null || _a === void 0 ? void 0 : _a.indexOf(url)) !== -1; })) {
                const authSchema = localStorage.getItem(AUTH_SCHEMA_KEY);
                requestConfig.headers.Authorization = `${authSchema ? `${authSchema} ` : ''}${token}`;
            }
            try {
                const userIdHeader = JSON.parse(localStorage.getItem(HEADER_USER_ID_KEY) || '');
                if ((userIdHeader === null || userIdHeader === void 0 ? void 0 : userIdHeader.value) && !((_a = requestConfig.url) === null || _a === void 0 ? void 0 : _a.includes('user/branches'))) {
                    requestConfig.headers[userIdHeader.header] = userIdHeader.value;
                }
            }
            catch (ex) {
                // noop
            }
            return requestConfig;
        });
    }
    client.interceptors.response.use(undefined, (error) => {
        if (errorHandler) {
            errorHandler(error);
        }
    });
    return client;
}
function setErrorHandler(handler) {
    errorHandler = handler;
}
export default {
    createClient,
    getAuthToken,
    setAuthToken,
    forgetAuthToken,
    setErrorHandler,
};
