/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { useEffect, useRef } from 'react';
import { Spin, Table } from 'antd';
import Styled from './Styled';
import { useDebouncedCallback } from 'use-debounce/lib';
const InfiniteTable = ({ loadMoreScrollThreshold = 100, loadMore, hasMore, loading, className, scroll, dataSource, ...tableProps }) => {
    const wrapperRef = useRef(null);
    const loadMoreRef = useRef(loadMore);
    const debouncedLoadMore = useDebouncedCallback(() => loadMoreRef.current(), 100);
    useEffect(() => {
        loadMoreRef.current = loadMore;
    }, [loadMore]);
    useEffect(() => {
        const onScroll = () => {
            if (hasMore &&
                document.scrollingElement &&
                document.scrollingElement.scrollTop + document.scrollingElement.clientHeight + loadMoreScrollThreshold >=
                    document.scrollingElement.scrollHeight) {
                debouncedLoadMore();
            }
        };
        document.addEventListener('scroll', onScroll);
        return () => document.removeEventListener('scroll', onScroll);
    }, [debouncedLoadMore, hasMore, loadMoreScrollThreshold]);
    return (React.createElement(Styled.Wrapper, { ref: wrapperRef, className: className, "$rowClickable": !!tableProps.onRow },
        React.createElement(Table, Object.assign({ dataSource: dataSource, loading: loading, pagination: false, scroll: {
                scrollToFirstRowOnChange: false,
                y: '100%',
                ...scroll,
            }, locale: { emptyText: loading ? React.createElement("div", { className: "ant-empty ant-empty-normal" }) : undefined } }, tableProps)),
        loading && !!(dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) && (React.createElement(Styled.LoaderWrapper, null,
            React.createElement(Spin, null)))));
};
export default InfiniteTable;
