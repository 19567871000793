import http, { endpoints } from '../http';
import { mapNewsFromDto } from './helpers';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class NewsApi {
    static async getTopThreeNews(locale) {
        var _a;
        let language = 'EN';
        if (locale.includes('da') || locale.includes('dk'))
            language = 'DA';
        if (locale.includes('sv') || locale.includes('se'))
            language = 'SV';
        if (locale.includes('no'))
            language = 'NO';
        if (locale.includes('vi'))
            language = 'VI';
        const response = await datahubClient.get(`/news/language/${language}?numberOfNews=3`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapNewsFromDto)) || [];
    }
    static async getNewsById(id, locale) {
        const allNews = await this.getTopThreeNews(locale);
        return allNews.find(news => news.id === id);
    }
}
