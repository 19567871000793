import React from 'react';
import { easing, screen, scrollbar } from '@libs/theme/mixins';
import { Steps as AntdSteps } from 'antd';
import { TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';
import Container from '../Container';
const HEIGHT_TRANSITION_MS_PER_PIXEL = 0.5;
const Steps = styled((props) => React.createElement(AntdSteps, Object.assign({}, props))) `
	width: 100%;
	max-width: 1000px;

	${screen.lte.sm `
		width: auto !important;
	`}
`;
const ContentTransitionGroup = styled(TransitionGroup) `
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;

	${({ $withAnimation, $deltaHeight, $height }) => $withAnimation
    ? css `
					transition: height ${Math.max(250, ($deltaHeight !== null && $deltaHeight !== void 0 ? $deltaHeight : 0) * HEIGHT_TRANSITION_MS_PER_PIXEL)}ms;
					height: ${$height === undefined ? 'auto' : `${$height}px`};
			  `
    : css `
					height: 100%;
			  `}

	${scrollbar()};

	> * {
		position: relative;
		left: 0;
		right: 0;
		width: 100%;
	}

	// Transition animations
	.transition-enter {
		position: absolute;
		transform: translateX(${({ $reverse }) => ($reverse ? '-100%' : '100%')});
	}

	.transition-enter-active {
		position: absolute;
		transform: translateX(0);
		transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('out')};
	}

	.transition-exit {
		transform: translateX(0);
	}

	.transition-exit-active {
		transform: translateX(${({ $reverse }) => ($reverse ? '100%' : '-100%')});
		transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('out')};
	}
`;
const CurrentStepWrapper = styled(props => React.createElement(Container, Object.assign({}, props))) `
	height: calc(100% - 96px);
`;
export default {
    Steps,
    ContentTransitionGroup,
    CurrentStepWrapper,
};
