import { TextGroup } from './models';
import { toCamelCase } from '@libs/util';
export const TextTypesGroupMap = {
    adText: TextGroup.Ads,
    areaDescription: TextGroup.AreaDescription,
    areaHeadline: TextGroup.AreaDescription,
    bannerHeadline: TextGroup.Ads,
    bannerText: TextGroup.Ads,
    caseDescription: TextGroup.HeadingAndIntroduction,
    caseHeadline: TextGroup.HeadingAndIntroduction,
    description: TextGroup.HeadingAndIntroduction,
    facebookHeadline: TextGroup.SocialMedia,
    facebookSubheadline: TextGroup.SocialMedia,
    facebookText: TextGroup.SocialMedia,
    googleHeadline: TextGroup.SocialMedia,
    googleText: TextGroup.SocialMedia,
    headline: TextGroup.HeadingAndIntroduction,
    headLine: TextGroup.HeadingAndIntroduction,
    inputForSpeakFilm: TextGroup.SpeechMovieText,
    inputTextFilm: TextGroup.SpeechMovieText,
    instagramText: TextGroup.SocialMedia,
    mainText: TextGroup.HeadingAndIntroduction,
    poiHotspotText: TextGroup.POI,
    sectionDescription: TextGroup.Section,
    sectionHeadline: TextGroup.Section,
    speakTextFilm: TextGroup.SpeechMovieText,
    speakTextSlideshow: TextGroup.SpeechMovieText,
    subHeadLine: TextGroup.HeadingAndIntroduction,
    twitterText: TextGroup.SocialMedia,
    usp: TextGroup.USP,
};
function separateIndexFromString(input = '') {
    const camelTitle = !input.match(/[\s_-]/g) ? input : toCamelCase(input);
    const indexPos = camelTitle.search(/[0-9]/g);
    const index = ~indexPos ? camelTitle.charAt(indexPos) : 0;
    const string = index ? camelTitle.replace(index, '') : camelTitle;
    return { string, index: +index };
}
export function getGroupByTextName(textName) {
    return TextTypesGroupMap[separateIndexFromString(textName).string] || TextGroup.Other;
}
export function mapTextFromDto({ content, title, name }) {
    const { string: normalizedTitle, index: titleIndex } = separateIndexFromString(title);
    return { content, title, name, normalizedTitle, titleIndex };
}
export function initializeTextGroups(groups) {
    const initialGroups = Object.values(TextGroup).map(value => ({
        groupName: value,
        texts: [],
    }), []);
    return groups
        .reduce((acc, group) => {
        const textGroupName = getGroupByTextName(group.name);
        const foundGroupIndex = acc.findIndex(({ groupName }) => groupName === textGroupName);
        if (foundGroupIndex !== -1) {
            const { string: normalizedTitle, index: titleIndex } = separateIndexFromString(group.title);
            acc[foundGroupIndex].texts.push({
                content: '',
                title: group.title,
                name: group.name,
                normalizedTitle,
                titleIndex,
            });
        }
        return acc;
    }, initialGroups)
        .filter(group => group.texts.length > 0);
}
export function groupTextsFromDto(dtoTexts) {
    const initialGroups = Object.values(TextGroup).map(value => ({
        groupName: value,
        texts: [],
    }), []);
    return dtoTexts
        .reduce((acc, dtoText) => {
        const textGroupName = getGroupByTextName(dtoText.name);
        const foundGroupIndex = acc.findIndex(({ groupName }) => groupName === textGroupName);
        if (foundGroupIndex !== -1) {
            acc[foundGroupIndex].texts.push(mapTextFromDto(dtoText));
        }
        return acc;
    }, initialGroups)
        .filter(group => group.texts.length > 0);
}
