import trim from 'lodash/trim';
function assignTranslationToResource(resource, { key, context, content }) {
    // skip assignment if not yet translated, except dynamic values
    if (content || key === 'notificationHeader' || key === 'collageGeneratingError') {
        let ctx = resource.translation;
        context.split('.').forEach(rawContextKey => {
            const contextKey = trim(rawContextKey, '"');
            if (!ctx[contextKey] || typeof ctx[contextKey] !== 'object') {
                ctx[contextKey] = {};
            }
            ctx = ctx[contextKey];
        });
        ctx[key] = content;
    }
}
function flattenResourceTranslation(resTranslation, parents = []) {
    return Object.keys(resTranslation).reduce((acc, key) => {
        const keys = [...parents, key];
        if (typeof resTranslation[key] === 'string') {
            return {
                ...acc,
                [keys.join('.')]: resTranslation[key],
            };
        }
        else {
            return {
                ...acc,
                ...flattenResourceTranslation(resTranslation[key], keys),
            };
        }
    }, {});
}
export function mapTranslationsToResource(translations, defaultResource) {
    const resource = defaultResource !== null && defaultResource !== void 0 ? defaultResource : { translation: {} };
    translations.forEach(translation => assignTranslationToResource(resource, translation));
    return resource;
}
export function mapResourceToTranslations(resource) {
    const flattenTranslations = flattenResourceTranslation(resource.translation);
    return Object.keys(flattenTranslations).map(pathKey => {
        const contextKeys = pathKey.split('.');
        const key = contextKeys.pop();
        return {
            key,
            context: contextKeys.map(contextKey => `"${contextKey}"`).join('.'),
            content: flattenTranslations[pathKey],
        };
    });
}
