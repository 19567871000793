import { color, fontSize } from '@libs/theme/mixins';
import styled from 'styled-components';
const Wrapper = styled.a `
	display: block;
	color: ${({ $primary }) => ($primary ? color('primary') : 'inherit')};
	text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};
	font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
	font-size: ${({ $large }) => ($large ? fontSize('lg') : fontSize('md'))};
	transition: opacity 0.3s;

	&:hover {
		color: ${({ $primary }) => ($primary ? color('primary') : 'inherit')};
		opacity: 0.6;
		text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};
	}
`;
export default {
    Wrapper,
};
