/* eslint @typescript-eslint/no-explicit-any: 0 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import format from './format';
import locale from './locale';
import resources from './resources';
import LocalizedError from './errors';
import dayjs from 'dayjs';
// set dayjs locale globally, i.e. for antd calendar
dayjs.locale(locale.getPreffered());
// Link yup to i18n
import './yup';
import { createTranslationLink } from './helpers';
export * from './hooks';
const PARAMS_REGEXP = /^([a-z0-9]+:[a-z0-9-]+\|)*([a-z0-9]+:[a-z0-9-]+)$/i;
const RANGE_PARAM_REGEXP = /^[0-9]+-[0-9]+$/;
function getFormatParamValue(rawValue) {
    if (RANGE_PARAM_REGEXP.test(rawValue)) {
        return rawValue.split('-').map(parseInt);
    }
    else if (parseInt(rawValue).toString() === rawValue) {
        return parseInt(rawValue);
    }
    else {
        return rawValue;
    }
}
function getFormatParams(formatFlags) {
    if (PARAMS_REGEXP.test(formatFlags)) {
        return formatFlags.split('|').reduce((acc, prop) => {
            const [key, value] = prop.split(':');
            acc[key] = getFormatParamValue(value);
            return acc;
        }, {});
    }
    else {
        return formatFlags;
    }
}
i18n
    .use(LanguageDetector) // detect the browser selected config
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
    resources: Object.keys(resources).reduce((acc, code) => ({
        ...acc,
        [code]: resources[code],
    }), {}),
    lng: locale.getPreffered(),
    fallbackLng: locale.getFallback(),
    load: 'languageOnly',
    debug: process.env.NODE_ENV !== 'production' && typeof window !== 'undefined',
    interpolation: {
        escapeValue: false,
        format: (value, formatFlags) => format(value, (formatFlags && getFormatParams(formatFlags))),
    },
});
i18n.on('languageChanged', lng => {
    dayjs.locale(lng);
});
export { LocalizedError, createTranslationLink };
