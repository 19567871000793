import http, { endpoints } from '../http';
import { mapArticleFromDto } from './helpers';
const datahubClient = http.createClient(endpoints.datahub);
export default class SupportApi {
    static async getArticles(locale) {
        var _a;
        let language = 'EN';
        if (locale.includes('da') || locale.includes('dk'))
            language = 'DA';
        if (locale.includes('sv') || locale.includes('se'))
            language = 'SV';
        if (locale.includes('no'))
            language = 'NO';
        if (locale.includes('vi'))
            language = 'VI';
        const response = await datahubClient.get(`/help-articles/language/${language}`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapArticleFromDto)) || [];
    }
    static async getArticleContentById(id, locale) {
        var _a;
        const allArticles = await this.getArticles(locale);
        return (_a = allArticles.find(article => article.id === id)) === null || _a === void 0 ? void 0 : _a.content;
    }
}
