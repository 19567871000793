/* eslint @typescript-eslint/no-explicit-any: 0 */
import dayjs from 'dayjs';
import locale from './locale';
function formatRelativeTime(value) {
    const relativeTimeFormatter = new Intl.RelativeTimeFormat(locale.getCurrentHierarchy());
    const timeDelta = Date.now() - value.getTime();
    const minute = 60000;
    const hour = minute * 60;
    const day = hour * 24;
    if (timeDelta <= hour) {
        const minutes = Math.ceil(timeDelta / minute);
        return relativeTimeFormatter.format(-minutes, 'minute');
    }
    if (timeDelta <= day) {
        const hours = Math.floor(timeDelta / hour);
        return relativeTimeFormatter.format(-hours, 'hour');
    }
    const days = Math.floor(timeDelta / day);
    return relativeTimeFormatter.format(-days, 'day');
}
function formatDateTime(value, formatType) {
    if (formatType === 'relative') {
        return formatRelativeTime(value);
    }
    else {
        const { date, time, format: momentFormat } = formatType;
        if (typeof momentFormat === 'string') {
            return dayjs(value).format(momentFormat);
        }
        else {
            return new Intl.DateTimeFormat(locale.getCurrentHierarchy(), {
                dateStyle: date,
                timeStyle: time,
            }).format(value);
        }
    }
}
function formatNumber(value, formatType) {
    const minimumFractionDigits = Number.isInteger(value)
        ? 0
        : (formatType === null || formatType === void 0 ? void 0 : formatType.decimals) instanceof Array
            ? formatType.decimals[0]
            : formatType === null || formatType === void 0 ? void 0 : formatType.decimals;
    const maximumFractionDigits = (formatType === null || formatType === void 0 ? void 0 : formatType.decimals) instanceof Array ? formatType.decimals[1] : formatType === null || formatType === void 0 ? void 0 : formatType.decimals;
    // force format looking in case currency is presented
    return new Intl.NumberFormat(locale.getCurrentHierarchy(), {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value) + ((formatType === null || formatType === void 0 ? void 0 : formatType.currency) ? ` ${formatType === null || formatType === void 0 ? void 0 : formatType.currency}` : '');
}
function formatString(value, formatType) {
    if (formatType === 'lowercase') {
        return value.toLowerCase();
    }
    else if (formatType === 'uppercase') {
        return value.toUpperCase();
    }
    else if (formatType === 'capitalize') {
        return value.length > 0 ? `${value[0].toUpperCase()}${value.slice(1)}` : value;
    }
    else {
        return value;
    }
}
function format(value, formatType) {
    if (value instanceof Date) {
        return formatDateTime(value, formatType);
    }
    else if (typeof value === 'number') {
        return formatNumber(value, formatType);
    }
    else if (typeof value === 'string') {
        return formatString(value, formatType);
    }
    else {
        return String(value);
    }
}
export default format;
