import React, { Fragment, useEffect } from 'react';
import { useLocalization } from '@libs/localization';
import { useFormikContext } from 'formik';
const Autolocalize = ({ children }) => {
    const { errors, touched, setFieldTouched } = useFormikContext();
    const { locale } = useLocalization();
    // Retrigger formik revalidation on language change
    useEffect(() => {
        const touchedFields = Object.keys(touched);
        Object.keys(errors)
            .filter(fieldName => touchedFields.includes(fieldName))
            .forEach(fieldName => setFieldTouched(fieldName));
    }, [locale]);
    return React.createElement(Fragment, null, children);
};
export default Autolocalize;
