export const DEFAULT_USER = {
    id: '',
    firstName: '',
    lastName: '',
    fullName: '',
    initials: '',
    email: '',
    isInternalUser: false,
    phoneNumber: '',
    visiblePrices: false,
    branches: [],
    _dto: {
        branches: [],
        email: '',
        id: '',
        isFromEsoft: false,
        firstName: '',
        lastName: '',
        fullName: ''
    },
};
