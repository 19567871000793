/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
import http from '../http';
import { mapAddressFromGoogleDto, mapAddressPredictionFromDawaDto, mapAddressPredictionFromGoogleDto } from './helpers';
const dawaClient = http.createClient('https://dawa.aws.dk', false);
const $window = typeof window !== 'undefined' ? window : undefined;
const GOOGLE_API_SCRIPT_ID = 'google-maps-api';
// Initialize google places API
if ($window && !document.head.querySelector(`#${GOOGLE_API_SCRIPT_ID}`)) {
    const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
    const script = document.createElement('script');
    script.async = true;
    script.id = GOOGLE_API_SCRIPT_ID;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    document.head.appendChild(script);
}
export default class AddressApi {
    static async getDawaAddressPredictions({ query }) {
        var _a;
        const response = await dawaClient.get('/adresser/autocomplete', {
            params: {
                q: query,
                side: 1,
                per_side: 20,
            },
        });
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapAddressPredictionFromDawaDto)) || [];
    }
    static async getDawaAddressFromPrediction({ prediction: { __internal_address }, }) {
        return __internal_address;
    }
    static async getGoogleAddressPredictions({ query, countryCode, }) {
        const service = new google.maps.places.AutocompleteService();
        const data = await service.getPlacePredictions({
            input: query,
            componentRestrictions: {
                country: countryCode,
            },
            types: ['address'],
        });
        return data.predictions.map(mapAddressPredictionFromGoogleDto).filter(Boolean);
    }
    static async getGoogleAddressFromPrediction({ prediction: { id } }) {
        const service = new google.maps.Geocoder();
        const data = await service.geocode({ placeId: id });
        const address = mapAddressFromGoogleDto(data.results[0]);
        if (!address) {
            throw new Error('Invalid prediction supplied to address api.');
        }
        return address;
    }
    static providerSupportsSubpremise(countryCode) {
        // This dictates whether or not to show subpremise fields in the UI alongside the address autocomplete
        // true: hide additional fields
        // false: show additional fields
        return countryCode === 'dk';
    }
    static providerUsesDawaApi(countryCode) {
        return countryCode === 'dk'; // DAWA only provides DK addresses
    }
    static async getAddressFromPrediction(params) {
        return this.providerUsesDawaApi(params.countryCode)
            ? this.getDawaAddressFromPrediction(params)
            : this.getGoogleAddressFromPrediction(params);
    }
    static async getAddressPredictions(params) {
        return this.providerUsesDawaApi(params.countryCode)
            ? this.getDawaAddressPredictions(params)
            : this.getGoogleAddressPredictions(params);
    }
}
