import http, { endpoints } from '../http';
import { mapTaskCategoryToDto, mapTaskFiltersFromDto, mapTaskOrderByParamToDto, mapTaskStatusToDto, mapVirtualStagingFromDto, mapCallProposalFromDto, mapDatahubTaskFromDto, mapInterviewQuestionFromDto, } from './helpers';
import { groupTextsFromDto } from '../texts/helpers';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class TasksApi {
    static async getList({ query, category = [], status = [], orderBy, orderByDirection, branchIds = [], page = 0, pageSize = 20, }) {
        var _a, _b, _c, _d, _e;
        const response = await datahubClient.post('/orders/tasks/search', {
            skip: page * pageSize,
            take: pageSize,
            branchIds: (branchIds === null || branchIds === void 0 ? void 0 : branchIds.length) !== 0 ? branchIds : undefined,
            searchString: query,
            types: category.length !== 0 ? category === null || category === void 0 ? void 0 : category.map(mapTaskCategoryToDto) : undefined,
            statuses: status.length !== 0 ? status === null || status === void 0 ? void 0 : status.map(mapTaskStatusToDto) : undefined,
            orderBy: mapTaskOrderByParamToDto(orderBy),
            orderByDirection: orderByDirection && orderByDirection === 'descend' ? 'desc' : 'asc',
        });
        return {
            page: ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.currentSkip) ? Math.ceil(response.data.currentSkip / pageSize) : page,
            pageSize: ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.take) || pageSize,
            totalItems: ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.total) || 0,
            items: ((_e = (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.map(mapDatahubTaskFromDto)) || [],
        };
    }
    static async getTotalTasksForUser(userId) {
        var _a;
        const response = await datahubClient.get(`/users/${userId}/tasks/total`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.total) || 0;
    }
    static async getOpenTasksByCaseId(caseId) {
        var _a;
        const response = await datahubClient.get(`/cases/${caseId}/tasks/open`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapDatahubTaskFromDto)) || [];
    }
    static async getFilters() {
        const response = await datahubClient.get('/orders/tasks/search/filters');
        return (response === null || response === void 0 ? void 0 : response.data) ? mapTaskFiltersFromDto(response.data) : undefined;
    }
    static async createComment({ taskId, comment, email }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/notes/add`, {
            note: comment,
            addedByEmail: email,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async completeTask(taskId) {
        const response = await datahubClient.patch(`/orders/tasks/${taskId}/complete`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async sendEmailForInterview({ taskId, ...params }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/interviews/send`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getInputForSpeakSlideShowText(caseId) {
        const response = await datahubClient.get('/orders/tasks/texts/InputForSpeakSlideShow', {
            params: { caseId },
        });
        return (response === null || response === void 0 ? void 0 : response.data) ? groupTextsFromDto(response.data) : [];
    }
    static async getPrefilledText(caseId) {
        const response = await datahubClient.get(`/orders/tasks/texts/Description`, { params: { caseId } });
        return (response === null || response === void 0 ? void 0 : response.data) ? groupTextsFromDto(response.data) : [];
    }
    static async getCopyWriteText(orderlineId) {
        const response = await datahubClient.get(`/orders/tasks/texts/${orderlineId}/TextSections`);
        return (response === null || response === void 0 ? void 0 : response.data) || [];
    }
    static async saveCopyWriteText(params) {
        const response = await datahubClient.post(`/orders/tasks/texts/complete`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getVirtualStagingData(type) {
        const response = await datahubClient.get('/orders/tasks/virtual-staging', {
            params: { type },
        });
        return (response === null || response === void 0 ? void 0 : response.data.map(mapVirtualStagingFromDto)) || [];
    }
    static async orderVirtualStaging({ taskId, ...params }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/complete/virtual-staging`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async requestImageObjectsRemoval(params) {
        const response = await datahubClient.post(`/orders/tasks/${params.taskId}/complete/objects-removal`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async requestRenovation(params) {
        const response = await datahubClient.post(`/orders/tasks/${params.taskId}/complete/indoor-renovation`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getCallProposal(bookingId) {
        var _a;
        const response = await datahubClient.get(`/Bookings/${bookingId}/proposals`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.suggestedProposal) ? mapCallProposalFromDto(response.data) : undefined;
    }
    static async confirmBookingPhotographer(proposal) {
        const response = await datahubClient.post(`/Bookings/${proposal.bookingId}/proposals/confirm`, {
            proposalId: proposal.id,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async contactPersonBooking(params) {
        const response = await datahubClient.post(`/Bookings/third-party-link`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async updateBookingType(params) {
        const response = await datahubClient.post(`/orders/tasks/${params.taskId}/updateType?bookingId=${params.bookingId}`, {
            changeToType: params.bookingType,
            responsibleUser: params.responsibleUser,
            person: params.person,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async partiallyFinishTask(taskId) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/partially`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async createBeforeAfterSlider({ taskId, ...params }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/complete/beforeafter`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async completeSoMeGif({ taskId, ...params }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/complete/some-gif`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getSocialMediaTypes() {
        const response = await datahubClient.get('/orders/tasks/sometypes');
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getSocialMediaSlideshowTypes() {
        // TODO: get from API. Mocked for now
        return ['Style1', 'Style2'];
    }
    static async completeSocialMediaTask({ taskId, type }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/complete/some`, {
            type,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async completeImageSelection({ taskId, materialIds, }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/complete/imageselector`, {
            materials: materialIds,
        });
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getOrderTasks(orderId) {
        var _a;
        const response = await datahubClient.get(`/orders/${orderId}/tasks`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapDatahubTaskFromDto)) || [];
    }
    static async getInterviewQuestions(taskId) {
        const response = await datahubClient.get(`/orders/tasks/${taskId}/interviews/questions`);
        return (response === null || response === void 0 ? void 0 : response.data) ? response.data.map(mapInterviewQuestionFromDto) : undefined;
    }
    static async saveInterviewQuestions({ taskId, answers, }) {
        const response = await datahubClient.post(`/orders/tasks/${taskId}/interviews/complete`, answers);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async submitUploads(params) {
        const response = await datahubClient.post(`/orders/tasks/${params.taskId}/uploads`, params.uploads);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getUploads(taskId) {
        const response = await datahubClient.get(`/orders/tasks/${taskId}/uploads`);
        return (response === null || response === void 0 ? void 0 : response.data) || [];
    }
    static async getBookingTaskByBookingId(bookingId) {
        const response = await datahubClient.get(`/Bookings/${bookingId}/task`);
        return response === null || response === void 0 ? void 0 : response.data;
    }
}
