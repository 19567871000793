import React from 'react';
import styled from 'styled-components';
import { color, fontSize, height, lineHeight, margin, rgbaColor, typography } from '@libs/theme/mixins';
import { Modal as _Modal } from 'antd';
const Modal = styled((props) => React.createElement(_Modal, Object.assign({}, props))) `
	.ant-modal-body {
		height: ${({ fullHeight }) => fullHeight && 'calc(100vh - 300px)'};
		> * {
			height: 100%;
		}
	}
`;
const Title = styled.div `
	line-height: ${lineHeight()};
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: ${height('sm')};

	> *:first-child {
		font-size: ${fontSize('xxl')};
		color: ${color('black')};
	}

	> *:last-child:not(:first-child) {
		font-size: ${fontSize('md')};
		color: ${rgbaColor('black', 0.45)};
	}
`;
const Content = styled.div `
	line-height: ${lineHeight()};
	${typography('md')}

	b {
		font: inherit;
	}

	h2 {
		margin-bottom: ${margin('sm')};
		text-transform: uppercase;
		font-size: ${fontSize('xl')};
	}

	p {
		margin-bottom: ${margin('sm')};
	}

	ol,
	ul {
		margin-bottom: ${margin('sm')};
	}

	// news stuff
	.gmail_default {
		${typography('md')}
		margin-bottom: ${margin('sm')};
	}

	img {
		max-width: 100%;
	}
`;
export default {
    Modal,
    Title,
    Content,
};
