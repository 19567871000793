export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["Complete"] = "COMPLETE";
    TaskStatus["Partial"] = "PARTIAL";
    TaskStatus["Open"] = "NOT_DONE";
    TaskStatus["Unknown"] = "UNKNOWN";
    TaskStatus["Cancelled"] = "CANCELLED";
})(TaskStatus || (TaskStatus = {}));
export var TaskCategory;
(function (TaskCategory) {
    TaskCategory["BookPhotographer"] = "BOOK_PHOTOGRAPHER";
    TaskCategory["UploadImages"] = "UPLOAD_IMAGES";
    TaskCategory["UploadVideos"] = "UPLOAD_VIDEOS";
    TaskCategory["VirtualStaging"] = "VIRTUAL_STAGING";
    TaskCategory["CopywriteTexts"] = "COPYWRITE_TEXTS";
    TaskCategory["Location"] = "LOCATION";
    TaskCategory["Person"] = "PERSON";
    TaskCategory["Basic"] = "BASIC";
    TaskCategory["IntroOutroSpeak"] = "INTRO_OUTRO_SPEAK";
    TaskCategory["BasicOpt"] = "BASIC_OPT";
    TaskCategory["Text"] = "TEXT";
    TaskCategory["VirtualStagingRemoveObjects"] = "VIRTUAL_STAGING_REMOVE_OBJECTS";
    TaskCategory["VirtualStagingIndoorRenovation"] = "VIRTUAL_STAGING_INDOOR_RENOVATION";
    TaskCategory["VirtualStagingOutdoorRenovation"] = "VIRTUAL_STAGING_OUTDOOR_RENOVATION";
    TaskCategory["VirtualStaging720"] = "VIRTUAL_STAGING_720";
    TaskCategory["VirtualStagingCommercial"] = "VIRTUAL_STAGING_COMMERCIAL";
    TaskCategory["VirtaulStagingCommercial720"] = "VIRTUAL_STAGING_COMMERCIAL_720";
    TaskCategory["CombineImages"] = "COMBINE_IMAGES";
    TaskCategory["Arial"] = "ARIAL";
    TaskCategory["PersonInfo"] = "PERSON_INFO";
    TaskCategory["IntroOutro"] = "INTRO_OUTRO";
    TaskCategory["VidibiliComment"] = "VIDIBILI_COMMENT";
    TaskCategory["SpeakInput"] = "SPEAK_INPUT";
    TaskCategory["ComText"] = "COMTEXT";
    TaskCategory["BeforeAfter"] = "BEFORE_AFTER";
    TaskCategory["NoStyleText"] = "TEXT-STYLE";
    TaskCategory["SoMe"] = "SOCIAL_MEDIA";
    TaskCategory["SoMeSlideshow"] = "SOCIAL_MEDIA_SLIDESHOW";
    TaskCategory["SomeGif"] = "SOCIAL_MEDIA_GIF";
    TaskCategory["ImageSelector"] = "IMAGE_SELECTOR";
})(TaskCategory || (TaskCategory = {}));
export var BookingTypes;
(function (BookingTypes) {
    BookingTypes["BookingPrimaryConfirmed"] = "PRIMARYCONFIRMED";
    BookingTypes["BookingSecondaryConfirmed"] = "SECONDARYCONFIRMED";
    BookingTypes["Booking3rdParty"] = "3RDPARTY";
    BookingTypes["Booking3rdPartyPrimaryConfirmed"] = "3RDPARTYPRIMARYCONFIRMED";
    BookingTypes["Booking3rdPartySecondaryConfirmed"] = "3RDPARTYSECONDARYCONFIRMED";
    BookingTypes["BookingEsoft"] = "ESOFT";
})(BookingTypes || (BookingTypes = {}));
