import { Col } from 'antd';
import React from 'react';
import GridContext, { defaultGridConfig } from './context';
import Row from './Row';
const GridComponent = ({ children, ...config }) => (React.createElement(GridContext.Provider, { value: {
        ...defaultGridConfig,
        ...config,
    } }, children));
const Grid = GridComponent;
Grid.Row = Row;
Grid.Col = Col;
export default Grid;
