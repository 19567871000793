import { isValidPhoneNumber } from './validation';
const SUPPORTED_VIDEO_FORMATS = ['mov', 'mp4', 'wmv', 'avi', 'mpeg', 'webm'];
export function normalizePhoneNumber(value) {
    return value && isValidPhoneNumber(value) ? value : undefined;
}
export function isVideoFormat(source) {
    const sourceNameArray = (source === null || source === void 0 ? void 0 : source.split('.')) || [];
    const sourceExtension = sourceNameArray[sourceNameArray.length - 1];
    return SUPPORTED_VIDEO_FORMATS.includes(sourceExtension);
}
export function toCamelCase(str) {
    // check if string is already in camelCase
    if (str.toLowerCase() === str && str.indexOf('_') === -1) {
        return str;
    }
    return str
        .split('_')
        .map((word, index) => {
        const lowerCaseString = word.toLowerCase();
        if (index === 0) {
            return lowerCaseString;
        }
        return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    })
        .join('');
}
export function fixDateInstance(dateString) {
    return dateString; // Temporary fix the logic to see if there is a date issue
    // if (!dateString) return dateString as string // fix TS Date params complaining
    // return dateString.includes('Z') || dateString.includes('+') ? dateString : `${dateString}Z` // this does not work in Firefox
}
export function languageToEnumNumber(locale) {
    if (locale.includes('da'))
        return 9;
    if (locale.includes('en'))
        return 10;
    if (locale.includes('sv') || locale.includes('se'))
        return 11;
    return 0;
}
export function convertLocaleToSupportedString(locale) {
    if (locale === null || locale === void 0 ? void 0 : locale.includes('en'))
        return 'en';
    if (locale === 'da-DK')
        return 'da';
    if (locale === 'sv-SE')
        return 'sv';
    if (locale === 'nb-NO' || locale === 'nn-NO')
        return 'no';
    return locale;
}
