export function isSameOrChildElement(container, candidate) {
    let parent = candidate;
    while (parent) {
        if (parent === container) {
            return true;
        }
        parent = parent.parentElement;
    }
    return false;
}
export function scrollToElement(element, offset = 0, forceAlignment = false) {
    if (document.scrollingElement) {
        const rect = element.getBoundingClientRect();
        if (rect.y - offset < 0 || forceAlignment) {
            document.scrollingElement.scrollTo({
                top: document.scrollingElement.scrollTop + rect.y - offset,
                behavior: 'smooth',
            });
        }
    }
}
