export function fitPoint(p, min, max) {
    return [Math.min(max[0], Math.max(min[0], p[0])), Math.min(max[1], Math.max(min[1], p[1]))];
}
export function fitPointToSize(p, size, padding = 0) {
    return fitPoint(p, [padding, padding], [size[0] - padding, size[1] - padding]);
}
export function getVectorLength(p0, p1) {
    return Math.sqrt(Math.pow(p0[0] - p1[0], 2) + Math.pow(p0[1] - p1[1], 2));
}
export function getVectorAngle(p0, p1) {
    return (Math.atan2(p1[1] - p0[1], p1[0] - p0[0]) * 180) / Math.PI;
}
/**
 * Gets click coordinates inside container
 *
 * @param el - Container DOM element
 * @param param1 - Object represents click position in entire document
 * @returns - Relative coordinates of the click inside the Container DOM element
 */
export function getPointInElement(el, [x, y]) {
    const rect = el.getBoundingClientRect();
    return [Math.min(Math.max(0, x - rect.left), el.clientWidth), Math.min(Math.max(0, y - rect.top), el.clientHeight)];
}
export function multiplyPoints(p0, p1) {
    return [p0[0] * p1[0], p0[1] * p1[1]];
}
/**
 * Gets the relative coordinate value inside container including size scaling
 *
 * @param p0 - Relative coordinates inside container
 * @param p1 - Size scaling for both axis
 * @returns - Relative coordinate value inside container including size scaling
 */
export function dividePoints(p0, p1) {
    return [p0[0] / p1[0], p0[1] / p1[1]];
}
export function sumPoints(p0, p1) {
    return [p0[0] + p1[0], p0[1] + p1[1]];
}
export function diffPoints(p0, p1) {
    return sumPoints(p0, multiplyPoints(p1, [-1, -1]));
}
export function getSquareDimensionsOfArea(area, border) {
    const widthArr = area.map(point => point[0]);
    const heightArr = area.map(point => point[1]);
    const padding = border ? border : 0;
    return [
        Math.round(Math.max.apply(null, widthArr) - Math.min.apply(null, widthArr)) + padding,
        Math.round(Math.max.apply(null, heightArr) - Math.min.apply(null, heightArr)) + padding,
    ];
}
export function getPositionOfArea(area) {
    return [
        Math.min.apply(null, area.map(point => point[0])),
        Math.min.apply(null, area.map(point => point[1])),
    ];
}
export function normalizeAreaCoords(shape, border) {
    const [startX, startY] = getPositionOfArea(shape);
    const padding = border ? border / 2 : 0;
    return shape.map(point => [Math.round(point[0] - startX) + padding, Math.round(point[1] - startY) + padding]);
}
export function checkIfPointBelongsToArea(point, area) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html
    const [x, y] = point;
    let inside = false;
    for (let i = 0, j = area.length - 1; i < area.length; j = i++) {
        const xi = area[i][0], yi = area[i][1];
        const xj = area[j][0], yj = area[j][1];
        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect)
            inside = !inside;
    }
    return inside;
}
export function getCenterOfArea(area, objectToPlace) {
    const [width, height] = getSquareDimensionsOfArea(area);
    if (objectToPlace)
        return [width / 2 - objectToPlace[0] / 2, height / 2 - objectToPlace[1] / 2];
    return [width / 2, height / 2];
}
