import styled from 'styled-components';
import { Button } from 'antd';
import React from 'react';
import { color, margin } from '@libs/theme/mixins';
const Wrapper = styled((props) => React.createElement(Button, Object.assign({}, props))) `
	color: ${color(['gray', '900'])};
	display: inline-flex;
	align-items: center;
	padding: 0;

	svg {
		height: 32px;
		width: 32px;
		margin-right: ${margin('xs')};
	}
`;
export default {
    Wrapper,
};
