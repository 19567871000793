import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Styled from './Styled';
const TRANSITION_TIMEOUT = 500;
const TRANSITION_TRACK_LENGTH = 100;
const SideLayout = ({ children, aside, asideSpan = 12, footer, contentKey }) => {
    const [transitioning, setTransitioning] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => setIsInitialized(true), []);
    return (React.createElement(Styled.Wrapper, null,
        React.createElement(Styled.Content, { xs: 24, xl: 24 - asideSpan, "$transitioning": transitioning ? 1 : 0 },
            React.createElement(TransitionGroup, null,
                React.createElement(CSSTransition, { key: contentKey, timeout: TRANSITION_TIMEOUT, classNames: 'transition', onEnter: () => setTransitioning(true), onEntered: () => setTransitioning(false) },
                    React.createElement(Styled.Children, { "$timeout": TRANSITION_TIMEOUT, "$trackLength": TRANSITION_TRACK_LENGTH },
                        children,
                        footer && React.createElement(Styled.Footer, null, footer))))),
        React.createElement(Styled.Aside, { span: asideSpan, "$initialized": isInitialized }, aside)));
};
export default SideLayout;
