import { useCallback } from 'react';
function getModalScrollingElement() {
    return typeof window !== 'undefined' ? document.querySelector('.ant-modal-wrap') : undefined;
}
export function useModalScroll() {
    const getScrollValue = useCallback(() => { var _a; return (_a = getModalScrollingElement()) === null || _a === void 0 ? void 0 : _a.scrollTop; }, []);
    const scrollTo = useCallback((value) => {
        var _a;
        return (_a = getModalScrollingElement()) === null || _a === void 0 ? void 0 : _a.scrollTo({
            top: value,
            behavior: 'smooth',
        });
    }, []);
    return {
        getScrollValue,
        scrollTo,
    };
}
