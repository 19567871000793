import React, { useEffect, useRef, useState } from 'react';
import { Select as FormikSelect } from 'formik-antd';
import { Select as AntdSelect } from 'antd';
import Styled from './Styled';
/*
Min width explanation
---------------------

When using the showSearch property ant requires you to set a fixed/min width to the input
otherwise when you are typing in the search the field shrinks to inner width = 0

Because this is an inline field, having a fixed width makes the input look bad due to
the arrow/search icon on the right getting variable margin from the text

The way min width works is:
- if there is a min width 0 (consider it unset) we assign it the current width of the field
- whenever the field changes its value we assign the min width back to 0 again and
let the effect execute

Why not assign it dirrectly the current value of the field?
:: Because when the field grows we cannot shrink it again (when the label is shorter
than the previously selected value)

Why not assign it to 0 and then immediately back to the current width of the input?
:: Because when react applies state changes it batches them so setting the state immediately
after eachother does not actually reset the field min width to 0 (so we can properly compute
new current width). Additionally we do not have the guarantee that the browser actually
repainted the element with the new style.

*/
const InlineSelect = ({ label, ...props }) => {
    var _a;
    const wrapperRef = useRef(null);
    const [minWidth, setMinWidth] = useState(0);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (minWidth === 0 && !!((_a = props.options) === null || _a === void 0 ? void 0 : _a.length)) {
            setMinWidth((_d = (_c = (_b = wrapperRef.current) === null || _b === void 0 ? void 0 : _b.querySelector('.ant-select')) === null || _c === void 0 ? void 0 : _c.clientWidth) !== null && _d !== void 0 ? _d : 0);
        }
    }, [minWidth, props.options]);
    return (React.createElement(Styled.Wrapper, { ref: wrapperRef, "$minSelectWidth": minWidth },
        label && React.createElement(Styled.Label, null, label),
        !!((_a = props.options) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(React.Fragment, null, props.name ? (React.createElement(FormikSelect, Object.assign({ name: props.name, bordered: false, size: 'small' }, props, { onChange: (...args) => {
                var _a;
                setMinWidth(0);
                (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, ...args);
            } }))) : (React.createElement(AntdSelect, Object.assign({ bordered: false, size: 'small' }, props, { onChange: (...args) => {
                var _a;
                setMinWidth(0);
                (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, ...args);
            } })))))));
};
export default InlineSelect;
