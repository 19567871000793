import React from 'react';
import { Button, InputNumber } from 'antd';
import { Add24, Subtract24 } from '@carbon/icons-react';
import Styled from './Styled';
const QuantityCounter = ({ disabled = false, quantity, maxQuantity, onChange }) => (React.createElement(Styled.Wrapper, { display: "flex", alignItems: "center", space: "xxs" },
    React.createElement(Button, { size: "small", disabled: disabled || quantity === 0, onClick: () => onChange(quantity - 1) },
        React.createElement(Subtract24, null)),
    React.createElement(InputNumber, { size: "small", min: 0, max: maxQuantity ? maxQuantity : 99, step: 1, value: quantity, disabled: disabled, onChange: onChange }),
    React.createElement(Button, { size: "small", disabled: disabled || quantity === 99 || (!!maxQuantity && quantity === maxQuantity), onClick: () => {
            if (!maxQuantity || quantity < maxQuantity) {
                onChange(quantity + 1);
            }
        } },
        React.createElement(Add24, null))));
export default QuantityCounter;
