import http, { endpoints } from '../http';
const datahubClient = http.createClient(`${endpoints.datahub}`);
const client = http.createClient('');
export default class S3Api {
    static async getS3Bucket() {
        const response = await datahubClient.get('/webshop/s3/endpoint');
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async signS3Upload(params) {
        const response = await datahubClient.post('/webshop/s3/upload/presigned', params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async s3Upload(params) {
        const { headers } = await client.put(params.preSignedUrl, params.file, {
            headers: {
                ['X-Amz-Acl']: 'public-read',
            },
            onUploadProgress: e => { var _a; return (_a = params.onProgress) === null || _a === void 0 ? void 0 : _a.call(params, (e.loaded / e.total) * 100); },
        });
        return headers.etag;
    }
}
