import React from 'react';
import { Affix, Layout } from 'antd';
import Styled from './Styled';
const AppLayout = ({ children, header, systemMessage }) => {
    return (React.createElement(Styled.Wrapper, null,
        React.createElement(Layout, null,
            React.createElement(Affix, null,
                !!systemMessage && React.createElement(Styled.SystemAlert, { message: systemMessage, type: "warning", showIcon: true }),
                React.createElement(Layout.Header, null,
                    React.createElement(Styled.Container, { "$type": 'header' },
                        React.createElement("div", null, header)))),
            React.createElement(Layout.Content, null,
                React.createElement(Styled.Container, { "$type": 'content' },
                    React.createElement("div", null, children))))));
};
export default AppLayout;
