import React from 'react';
import { Modal as AntdModal } from 'antd';
import styled, { css } from 'styled-components';
import { borderRadius, easing, margin, rgbaColor, screen } from '@libs/theme/mixins';
import IconButton from '../../IconButton';
import { TransitionGroup as _TransitionGroup } from 'react-transition-group';
const Modal = styled((props) => React.createElement(AntdModal, Object.assign({}, props))) `
	max-width: 80vw;
	top: 70px;

	${screen.lte.md `
        top: 5vh !important;
        width: auto !important;
        max-width: 90vw !important;
    `}

	.ant-modal-header, .ant-modal-footer {
		display: none;
	}

	.ant-modal-body {
		padding: 0;
		border-radius: ${borderRadius('sm')};
		min-height: 200px;
		max-height: calc(100vh - 80px);
		overflow: hidden;

		${screen.lte.lg `
            max-height: 90vh;
        `}
	}

	.ant-modal-content {
		min-width: auto;
	}

	transition: width ${({ $widthTransitionDuration }) => $widthTransitionDuration}ms,
		height ${({ $heightTransitionDuration }) => $heightTransitionDuration}ms;

	width: ${({ $width }) => ($width ? `${$width}px` : 'auto')} !important;
	height: ${({ $height }) => ($height ? `${$height}px` : 'auto')} !important;
`;
const commonButtonStyles = css `
	position: absolute !important;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;

	${screen.lte.md `
		background: ${rgbaColor('white', 0.9)};
	`}
`;
const PrevButton = styled(IconButton) `
	${commonButtonStyles};
	left: -120px;

	${screen.lte.lg `
		left: ${margin('md')};
	`}
`;
const NextButton = styled(IconButton) `
	${commonButtonStyles};
	right: -120px;

	${screen.lte.lg `
		right: ${margin('md')};
	`}
`;
const Slide = styled.div `
	> * {
		border: none;
		max-width: 80vw;
		max-height: calc(100vh - 80px);
		width: ${({ $type }) => ($type === 'embedCode' || $type === 'iframe' ? '68vw' : 'auto')};
		height: ${({ $type }) => ($type === 'embedCode' || $type === 'iframe' ? 'calc(100vh - 200px)' : 'auto')};

		&:not(img) {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}

		${screen.lte.md `
			max-width: 90vw;
			max-height: 90vh;
			width: ${({ $type }) => $type === 'embedCode' || $type === 'iframe' ? '90vw' : 'auto'};
			height: ${({ $type }) => $type === 'embedCode' || $type === 'iframe' ? '90vh' : 'auto'};
        `}
	}

	// There is a weird margin for videos at bottom which we have to get rid of
	margin-bottom: ${({ $type }) => ($type === 'video' ? '-7px' : 0)};
`;
const TransitionGroup = styled(_TransitionGroup) `
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		height: 100%;
		width: 100%;

		${({ $imageContainer }) => !$imageContainer &&
    css `
				padding-top: 56.5%;
			`}
	}

	.transition-enter {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
	}

	.transition-exit {
		position: relative;
		opacity: 1;
		transition: opacity ${({ $duration }) => $duration}ms ${easing('inOut')};
		z-index: 1;
	}

	.transition-exit-active {
		opacity: 0;
	}
`;
const Preloader = styled.div `
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;
	overflow: hidden;
	user-select: none;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export default {
    Modal,
    PrevButton,
    NextButton,
    Slide,
    TransitionGroup,
    Preloader,
};
