import styled, { css } from 'styled-components';
import { padding, scrollbar } from '@libs/theme/mixins';
const Wrapper = styled.div `
	position: relative;
	max-width: 100%;

	& .ant-table-wrapper,
	& .ant-spin-nested-loading,
	& .ant-spin-container,
	& .ant-table,
	& .ant-table-container {
		width: 100%;
		height: 100%;
	}

	.ant-table-container {
		display: flex;
		flex-direction: column;
	}

	.ant-table-header {
		display: flex;
		flex: 0 0 auto;
	}

	.ant-table-body {
		display: flex;
		min-height: 0;
		flex: 1;
		overflow-y: auto !important;
		${scrollbar()}
	}

	${({ $rowClickable }) => $rowClickable &&
    css `
			.ant-table-row {
				cursor: pointer;
			}
		`}
`;
const LoaderWrapper = styled.div `
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: ${padding('xxl', 0)};
`;
export default {
    Wrapper,
    LoaderWrapper,
};
