import http, { endpoints } from '../http';
import { groupTextsFromDto, initializeTextGroups, mapTextFromDto } from './helpers';
import { LocalizedError } from '@libs/localization';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class TextsApi {
    static async getCaseTexts(caseId) {
        var _a;
        const response = await datahubClient.get(`/cases/${caseId}/texts/current`);
        return ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.textSections) ? groupTextsFromDto(response.data.textSections) : [];
    }
    static async getTextGroups() {
        const response = await datahubClient.get('/Texts/GetAllTextSections');
        return (response === null || response === void 0 ? void 0 : response.data) ? initializeTextGroups(response.data) : [];
    }
    static async saveCaseText(params) {
        var _a;
        const response = await datahubClient.post(`/cases/${params.caseId}/texts/${params.name}`, {
            content: params.content,
        });
        if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.success)) {
            throw new LocalizedError('modals.editText.error');
        }
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getTextHistory(params) {
        var _a, _b, _c;
        const response = await datahubClient.get(`/cases/${params.caseId}/texts/${params.name}`);
        return (((_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.textSectionsHistory) === null || _b === void 0 ? void 0 : _b.reduce((memo, next) => {
            if (!memo.some(({ content }) => content === next.content)) {
                return memo.concat([next]);
            }
            return memo;
        }, [])) === null || _c === void 0 ? void 0 : _c.map(mapTextFromDto)) || []);
    }
}
