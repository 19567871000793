import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { Input } from 'antd';
const characterLimitRegex = /[^0-9a-åA-Å,._:;/ "*'?()%!´`&+@]/gi;
const FormikTextArea = inputProps => {
    const { setFieldValue, handleBlur, values } = useFormikContext();
    const value = getIn(values, inputProps.name);
    const onChange = (e) => {
        const valueToSet = inputProps.limitCharacters ? e.target.value.replace(characterLimitRegex, '') : e.target.value;
        setFieldValue(inputProps.name, valueToSet);
    };
    return React.createElement(Input.TextArea, Object.assign({ onChange: onChange, value: value, onBlur: handleBlur }, inputProps));
};
export default FormikTextArea;
