/* eslint @typescript-eslint/no-explicit-any: 0 */
import { useFormikContext } from 'formik';
import React, { Fragment, useEffect } from 'react';
// Formik does not provide a way to dynamically validate specific initial values so we need this hackyish way to do it
// This is especially useful when initial values are loaded and because the field is invalid we cannot submit. Because Formik
// does not initially validate (and for good reasons) there is not going be to any feedback shown to the user that some fields are incorrect
const Autotouch = ({ name, enabled = true, validate = false, children }) => {
    const { setFieldTouched, touched } = useFormikContext();
    useEffect(() => {
        const nameKeys = name.split('.');
        const isTouched = nameKeys.reduce((current, key) => current && current[key], touched);
        if (enabled && !isTouched) {
            setFieldTouched(name, true, validate);
        }
    });
    return React.createElement(Fragment, null, children);
};
export default Autotouch;
