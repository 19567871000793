/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { ComponentType, forwardRef, PropsWithChildren } from 'react'
import Styled from './Styled'
import { IContainerConfig } from './types'

interface ContainerProps extends IContainerConfig {
	as?: keyof JSX.IntrinsicElements | ComponentType
	className?: string
	onClick?: (e: any) => void
}

const Container = forwardRef<HTMLDivElement, PropsWithChildren<ContainerProps>>(
	({ as: asProp, className, children, onClick, ...config }, ref) => {
		return (
			<Styled.Wrapper onClick={onClick} ref={ref} as={asProp as any} className={className} $config={config}>
				{children}
			</Styled.Wrapper>
		)
	}
)

export default Container
