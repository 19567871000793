import { css } from 'styled-components';
import { height, color, padding, rgbaColor } from '../mixins';
import themeAntd from '../theme.antd';
export default css `
	.PhoneInput {
		height: ${height('md')};
		width: 100%;
		border: 1px solid ${themeAntd['border-color-base']};
		border-radius: ${themeAntd['border-radius-sm']};
		font-size: ${themeAntd['font-size-base']};
		font-family: ${themeAntd['font-family']};
		transition: 0.3s;
		padding: 0 ${padding('sm')};

		.PhoneInputInput {
			border: none !important;
			outline: none !important;

			&:disabled {
				background: transparent !important;
			}
		}
	}

	.PhoneInput--focus {
		border-color: ${color('primary')};
		border-radius: ${themeAntd['border-radius-base']};
		box-shadow: 0 0 0 2px ${rgbaColor('primary', 0.2)};
	}

	.PhoneInput--disabled {
		background: ${color(['gray', '200'])};
		color: rgba(0, 0, 0, 0.25);
	}

	.PhoneInputCountryIcon {
		box-shadow: none !important;
		background: none !important;
	}

	.PhoneInputCountrySelectArrow {
		color: inherit !important;
	}

	.ant-form-item-has-error {
		.form-control {
			border-color: ${color('error')} !important;
		}
	}
`;
