import styled from 'styled-components';
const Wrapper = styled.div `
	position: relative;

	> * {
		position: relative;
	}
`;
export default {
    Wrapper,
};
