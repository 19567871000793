import { css } from 'styled-components';
export default css `
	@keyframes bounce {
		0% {
			transform: scale(0);
			opacity: 0;
		}

		70% {
			transform: scale(1.2);
			opacity: 1;
		}

		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
`;
