import { useFormikContext, getIn } from 'formik';
import React, { useMemo } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const FormikPhoneInput = ({ name, ...phoneInputProps }) => {
    const { values, setFieldValue, handleBlur } = useFormikContext();
    const fixedCountry = useMemo(() => {
        var _a;
        const formattedCountry = (_a = phoneInputProps.country) === null || _a === void 0 ? void 0 : _a.toUpperCase();
        switch (formattedCountry) {
            case 'DA':
                return 'DK';
            default:
                return formattedCountry;
        }
    }, [phoneInputProps.country]);
    const formattedFormValue = useMemo(() => {
        const formValue = getIn(values, name);
        return (formValue === null || formValue === void 0 ? void 0 : formValue.includes('+')) || !fixedCountry ? formValue : `+${getCountryCallingCode(fixedCountry)}${formValue}`;
    }, [values, name, fixedCountry]);
    return (React.createElement(PhoneInput, Object.assign({ value: formattedFormValue, onChange: value => setFieldValue(name, value), defaultCountry: fixedCountry, name: name, onFocus: (e) => {
            var _a, _b;
            const target = e.target;
            (_b = (_a = target === null || target === void 0 ? void 0 : target.closest('form')) === null || _a === void 0 ? void 0 : _a.querySelectorAll('input')) === null || _b === void 0 ? void 0 : _b.forEach((input) => {
                if (input.name !== name) {
                    input.setAttribute('autocomplete', input.name + '1');
                }
            });
        }, onBlur: (e) => {
            var _a, _b;
            const target = e.target;
            (_b = (_a = target === null || target === void 0 ? void 0 : target.closest('form')) === null || _a === void 0 ? void 0 : _a.querySelectorAll('input')) === null || _b === void 0 ? void 0 : _b.forEach((input) => {
                if (input.name !== name) {
                    input.removeAttribute('autocomplete');
                }
            });
            handleBlur(e);
        } }, phoneInputProps)));
};
export default FormikPhoneInput;
