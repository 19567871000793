import React, { useContext, forwardRef } from 'react';
import { useTheme } from 'styled-components';
import GridContext from './context';
import Styled from './Styled';
// Preset & extended antd grid row
const Row = forwardRef(({ gutter, children, ...restProps }, ref) => {
    const defaultGutterParams = useContext(GridContext).gutter;
    const gutterParams = gutter !== null && gutter !== void 0 ? gutter : defaultGutterParams;
    const theme = useTheme();
    const gutterKeys = gutterParams instanceof Array ? gutterParams : [gutterParams, gutterParams];
    return (React.createElement(Styled.Row, Object.assign({ ref: ref, gutter: [gutterKeys[0] ? theme.margins[gutterKeys[0]] : 0, gutterKeys[1] ? theme.margins[gutterKeys[1]] : 0] }, restProps), children));
});
export default Row;
