import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
const SCREEN_SCALE_ORDER = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
export function useScreenMatch() {
    const theme = useTheme();
    const [matches, setMatches] = useState({
        xxs: false,
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
    });
    useEffect(() => {
        const mediaQueries = SCREEN_SCALE_ORDER.reduce((acc, key, index) => {
            const scaleKey = key;
            const breakpointKey = key;
            const query = [
                index > 0 && `(min-width: ${theme.breakpoints[breakpointKey]}px)`,
                index < SCREEN_SCALE_ORDER.length - 1 &&
                    `(max-width: ${theme.breakpoints[SCREEN_SCALE_ORDER[index + 1]] - 1}px)`,
            ]
                .filter(Boolean)
                .join(' and ');
            acc[scaleKey] = window.matchMedia(query);
            return acc;
        }, {});
        // Set initial values
        setMatches(SCREEN_SCALE_ORDER.reduce((acc, key) => ({
            ...acc,
            [key]: mediaQueries[key].matches,
        }), {}));
        // Subscribe to media query changes
        const listeners = SCREEN_SCALE_ORDER.reduce((acc, key) => {
            var _a, _b;
            acc[key] = (e) => setMatches(prevMatches => ({
                ...prevMatches,
                [key]: e.matches,
            }));
            (_b = (_a = mediaQueries[key]) === null || _a === void 0 ? void 0 : _a.addEventListener) === null || _b === void 0 ? void 0 : _b.call(_a, 'change', acc[key]);
            return acc;
        }, {});
        return () => {
            SCREEN_SCALE_ORDER.forEach(key => { var _a, _b; return (_b = (_a = mediaQueries[key]) === null || _a === void 0 ? void 0 : _a.removeEventListener) === null || _b === void 0 ? void 0 : _b.call(_a, 'change', listeners[key]); });
        };
    }, [theme]);
    const eq = useCallback((screen) => matches[screen], [matches]);
    const lte = useCallback((screen) => {
        const index = SCREEN_SCALE_ORDER.indexOf(screen);
        for (let i = index; i >= 0; i--) {
            if (matches[SCREEN_SCALE_ORDER[i]]) {
                return true;
            }
        }
        return false;
    }, [matches]);
    const gte = useCallback((screen) => {
        const index = SCREEN_SCALE_ORDER.indexOf(screen);
        for (let i = index; i < SCREEN_SCALE_ORDER.length; i++) {
            if (matches[SCREEN_SCALE_ORDER[i]]) {
                return true;
            }
        }
        return false;
    }, [matches]);
    const between = useCallback((min, max) => gte(min) && lte(max), [gte, lte]);
    const map = useCallback((values) => {
        const size = SCREEN_SCALE_ORDER.find(key => matches[key]);
        let index = size ? SCREEN_SCALE_ORDER.indexOf(size) : -1;
        while (!values.hasOwnProperty(SCREEN_SCALE_ORDER[index]) && index >= 0) {
            index--;
        }
        return index > -1 ? values[SCREEN_SCALE_ORDER[index]] : values.xxs;
    }, [matches]);
    return {
        eq,
        gte,
        lte,
        between,
        map,
    };
}
