import dayjs from 'dayjs';
import http, { endpoints } from '../http';
import { mapCaseDetailsRequestParamsToDto, mapCaseFiltersFromDto, mapDatahubCaseFromDto } from './helpers';
import { LocalizedError } from '@libs/localization';
import startCase from 'lodash/startCase';
import { mapContactPersonFromDto } from '../users/helpers';
const datahubClient = http.createClient(`${endpoints.datahub}`);
export default class CasesApi {
    static async getDatahubCase(id) {
        const response = await datahubClient.get(`/Cases/${id}`);
        return (response === null || response === void 0 ? void 0 : response.data) ? mapDatahubCaseFromDto(response.data) : undefined;
    }
    static async getDatahubCases({ page = 0, pageSize = 20, departmentId, fromDate, toDate, query, category, orderBy, orderByDirection, includeNoOrders, status, }) {
        const response = await datahubClient.post('/Cases/Search', {
            skip: page * pageSize,
            take: pageSize,
            branchIds: departmentId,
            modifiedDateFrom: fromDate && dayjs(fromDate).format('YYYY-MM-DD'),
            modifiedDateTo: toDate && dayjs(toDate).format('YYYY-MM-DD'),
            deliveredProductCategories: category,
            orderStatuses: status ? status.map(value => startCase(value.toLowerCase())) : undefined,
            searchKey: query,
            sortOrder: orderBy,
            sortDirection: orderByDirection && orderByDirection === 'descend' ? 'desc' : 'asc',
            mustHaveOrder: !includeNoOrders,
        });
        const { data } = response || {};
        return {
            page: page,
            pageSize: pageSize,
            totalItems: (data === null || data === void 0 ? void 0 : data.total) || 0,
            items: (data === null || data === void 0 ? void 0 : data.results.map(mapDatahubCaseFromDto)) || [],
        };
    }
    static async getFilters() {
        const response = await datahubClient.get('/Cases/Search/Filters');
        return (response === null || response === void 0 ? void 0 : response.data) && mapCaseFiltersFromDto(response.data);
    }
    static async getAddressByCaseId(caseId) {
        const { addressObj } = (await this.getDatahubCase(caseId)) || {};
        return addressObj;
    }
    static async updateCaseAddress({ caseId, ...params }) {
        const response = await datahubClient.post(`/cases/${caseId}/address`, params);
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async checkIfCaseExistsByCaseId(caseId) {
        const foundCase = await this.getDatahubCase(caseId);
        return !!foundCase;
    }
    static async checkIfCaseExists(referenceOrId, branchId) {
        const filters = await this.getFilters();
        const { items } = await this.getDatahubCases({
            pageSize: 1,
            departmentId: [branchId],
            query: referenceOrId,
            category: filters.categories,
            status: filters.statuses,
            includeNoOrders: true,
        });
        return !!(items === null || items === void 0 ? void 0 : items[0]);
    }
    static async upsertCase(params) {
        var _a;
        const response = await datahubClient.post('/Cases/upsert/webshop', mapCaseDetailsRequestParamsToDto(params));
        if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.caseId)) {
            throw new LocalizedError(params.case.id ? 'cases.messages.caseDetailsUpdateFailed' : 'cases.messages.caseCreateFailed');
        }
        return response.data.caseId;
    }
    static async getContactPersonsByCaseId(caseId) {
        const response = await datahubClient.get(`/cases/${caseId}/contact-persons`);
        return (response === null || response === void 0 ? void 0 : response.data) ? response.data.map(mapContactPersonFromDto) : [];
    }
}
