import CasesApi from '../cases';
import http, { endpoints } from '../http';
import { mapBookingInfoFromDto, mapDatahubOrderDtoToSimplifiedOrder, mapDatahubOrderLineFromDto, mapFeedbackParamsToDto, mapPublicBookingFromDto, mapSimplifiedOrderFromDto, } from './helpers';
import { LocalizedError } from '@libs/localization';
import GoogleApi from '../google';
import TasksApi from '../tasks';
const datahubClient = http.createClient(endpoints.datahub);
export default class OrdersApi {
    static async getPropertyTypes() {
        const response = await datahubClient.get('/property-types');
        return (response === null || response === void 0 ? void 0 : response.data) ? response.data : [];
    }
    static async submitFeedback(params) {
        const response = await datahubClient.post('/orders/rating/w2', mapFeedbackParamsToDto(params));
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async createOrder({ case: caseObj, address = {}, contactPersons, property, package: packageObj, products, keyPickupAddress, responsibleUser, watchers, hasExternalStylist, photographerComment, endUserInvoice, electronicInvoice, departmentId, branchId, anyTaskIncludesBooking, orderId, }) {
        var _a;
        const caseId = await CasesApi.upsertCase({
            case: caseObj,
            address,
            property,
            contactPersons,
            branchId,
        });
        const response = await datahubClient.post('/orders/new/webshop', {
            caseId,
            orderId,
            bookingInfo: anyTaskIncludesBooking
                ? {
                    bookingRequested: anyTaskIncludesBooking,
                    address: {
                        street: address.street,
                        buildingNumber: address.houseNumber,
                        buildingFloor: address.floor,
                        buildingDoor: address.appartmentNumber,
                        cityArea: address.area,
                        city: address.city,
                        zip: address.postNumber,
                    },
                    pickUpKeyRequested: !!keyPickupAddress,
                    pickUpKeyAddress: {
                        street: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.street,
                        buildingNumber: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.houseNumber,
                        buildingFloor: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.floor,
                        buildingDoor: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.appartmentNumber,
                        cityArea: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.area,
                        city: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.city,
                        zip: keyPickupAddress === null || keyPickupAddress === void 0 ? void 0 : keyPickupAddress.postNumber,
                    },
                    commentForPhotographers: photographerComment,
                    contactPersons: 
                    // hack to safe broken data
                    (contactPersons === null || contactPersons === void 0 ? void 0 : contactPersons.filter(item => !!item).map(contactPerson => {
                        var _a, _b;
                        return ({
                            contactType: contactPerson.type,
                            firstName: (_a = contactPerson.name.split(' ')) === null || _a === void 0 ? void 0 : _a[0],
                            lastName: (_b = contactPerson.name.split(' ')) === null || _b === void 0 ? void 0 : _b[1],
                            phone: contactPerson.phone,
                            alternativePhone: contactPerson.phoneAlt,
                            email: contactPerson.email,
                        });
                    })) || [],
                    salesStylingRequested: hasExternalStylist,
                }
                : null,
            propertyInfo: (property === null || property === void 0 ? void 0 : property.livingArea)
                ? {
                    basementArea: property === null || property === void 0 ? void 0 : property.basementArea,
                    registeredArea: property === null || property === void 0 ? void 0 : property.livingArea,
                    totalStoreys: property === null || property === void 0 ? void 0 : property.floors,
                }
                : null,
            responsibleEmail: responsibleUser === null || responsibleUser === void 0 ? void 0 : responsibleUser.email,
            package: packageObj === null || packageObj === void 0 ? void 0 : packageObj.id,
            productChoices: (_a = packageObj === null || packageObj === void 0 ? void 0 : packageObj.choices) === null || _a === void 0 ? void 0 : _a.reduce((memo, { group, selectedProductId, selectedPackageId, products: productChoices, packages: packageChoices }) => {
                if (selectedPackageId && packageChoices) {
                    const foundPackage = packageChoices.find(({ id }) => id === selectedPackageId);
                    if (foundPackage) {
                        memo.push({
                            itemId: foundPackage.itemNumber,
                            config: foundPackage.config,
                            group,
                            quantity: 1,
                        });
                    }
                }
                else if (!Array.isArray(selectedProductId) && productChoices) {
                    const foundProduct = productChoices.find(({ id }) => id === selectedProductId);
                    if (foundProduct) {
                        memo.push({
                            itemId: foundProduct.itemNumber,
                            config: foundProduct.config,
                            group,
                            quantity: foundProduct.quantity,
                        });
                    }
                }
                return memo;
            }, []),
            products: (products === null || products === void 0 ? void 0 : products.map(({ id, quantity }) => ({
                productId: id,
                quantity,
            }))) || [],
            invoicing: {
                endUserInvoicing: endUserInvoice
                    ? {
                        requested: !!endUserInvoice,
                        address: {
                            street: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.street,
                            buildingNumber: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.houseNumber,
                            buildingFloor: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.floor,
                            buildingDoor: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.appartmentNumber,
                            cityArea: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.area,
                            city: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.city,
                            zip: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.address.postNumber,
                        },
                        firstName: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.firstName,
                        lastName: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.lastName,
                        email: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.email,
                        phone: endUserInvoice === null || endUserInvoice === void 0 ? void 0 : endUserInvoice.phone,
                    }
                    : null,
                businessInvoicing: (electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.cvr)
                    ? {
                        requested: !!electronicInvoice,
                        address: {
                            street: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.street,
                            buildingNumber: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.houseNumber,
                            buildingFloor: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.floor,
                            buildingDoor: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.appartmentNumber,
                            cityArea: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.area,
                            city: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.city,
                            zip: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.address.postNumber,
                        },
                        firstName: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.name,
                        email: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.email,
                        phone: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.phone,
                        vat: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.cvr,
                        ean: electronicInvoice.ean,
                        comment: electronicInvoice === null || electronicInvoice === void 0 ? void 0 : electronicInvoice.comment,
                    }
                    : null,
            },
            watcherEmails: (watchers === null || watchers === void 0 ? void 0 : watchers.map(item => item === null || item === void 0 ? void 0 : item.email)) || [],
        });
        const creationResponse = (response === null || response === void 0 ? void 0 : response.data) || {};
        if (!(creationResponse === null || creationResponse === void 0 ? void 0 : creationResponse.success)) {
            throw new LocalizedError('orders.pages.new.createOrderFailed');
        }
        if (process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
            await GoogleApi.sendOrderCompleted(departmentId, caseObj.reference, creationResponse.orderId);
        }
        const tasks = (await TasksApi.getOrderTasks(creationResponse.orderId)) || [];
        return {
            salesId: creationResponse.salesId,
            tasks,
            caseId,
            orderId: creationResponse.orderId,
        };
    }
    static async updateResponsibleAgent(params) {
        const response = await datahubClient.post(`/orders/${params.orderId}/responsible`, {
            userId: params.userId,
        });
        if (!(response === null || response === void 0 ? void 0 : response.data.success)) {
            throw new LocalizedError('orders.pages.details.responsibleUpdateError');
        }
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async updateOrderWatchers(params) {
        const response = await datahubClient.post(`/orders/${params.orderId}/watchers`, {
            id: params.orderId,
            userIds: params.userIds,
        });
        if (!(response === null || response === void 0 ? void 0 : response.data)) {
            throw new LocalizedError('orders.pages.details.watchersUpdateError');
        }
        return response === null || response === void 0 ? void 0 : response.data;
    }
    static async getBookingById(bookingId) {
        const response = await datahubClient.get(`/Bookings/${bookingId}`);
        return (response === null || response === void 0 ? void 0 : response.data) ? mapPublicBookingFromDto(response.data) : undefined;
    }
    static async getOrderLines(orderId) {
        var _a;
        const response = await datahubClient.get(`/orders/${orderId}/orderlines`);
        return (response === null || response === void 0 ? void 0 : response.data) ? (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(mapDatahubOrderLineFromDto) : [];
    }
    static async getOrderLineActions(orderlineIds) {
        const response = await datahubClient.get(`/orders/orderlines/actions`, {
            params: {
                orderlineIds,
            },
        });
        return (response === null || response === void 0 ? void 0 : response.data) || [];
    }
    static async addOrderLines(params) {
        var _a;
        const response = await datahubClient.post(`/orders/${params.orderId}/orderlines/add`, params.caseProducts.map(({ quantity, itemNumber, config, variant }) => ({
            itemId: itemNumber,
            config,
            quantity,
            variant,
        })));
        if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.some(({ success }) => !success)) {
            throw new LocalizedError('modals.addExtraOrderLine.orderUpdateError');
        }
    }
    static async getCaseOrders(id) {
        const response = await datahubClient.get(`/cases/${id}/orders`);
        return (response === null || response === void 0 ? void 0 : response.data) ? response.data.map(mapSimplifiedOrderFromDto) : [];
    }
    static async getDatahubOrderById(orderId) {
        const response = await datahubClient.get(`/orders/${orderId}`);
        return (response === null || response === void 0 ? void 0 : response.data) ? mapDatahubOrderDtoToSimplifiedOrder(response.data) : undefined;
    }
    static async getOrderWatchers(orderId) {
        const response = await datahubClient.get(`/orders/${orderId}/watchers`);
        return (response === null || response === void 0 ? void 0 : response.data) || [];
    }
    static async getBookingInfo(orderId) {
        const response = await datahubClient.get(`/orders/${orderId}/booking-info`);
        return (response === null || response === void 0 ? void 0 : response.data) ? mapBookingInfoFromDto(response.data) : undefined;
    }
}
