import React from 'react';
import Styled from './Styled';
const Tooltip = props => {
    const { text, imgUrl, maxWidth, children, display, lightTheme, ...tooltipProps } = props;
    const title = (React.createElement(Styled.TooltipContent, { lightTheme: lightTheme },
        imgUrl && React.createElement("img", { src: imgUrl, alt: text }),
        text && React.createElement("p", null, text)));
    tooltipProps.overlayStyle =
        maxWidth && maxWidth === 'fit-content' ? { maxWidth: 'fit-content' } : { maxWidth: `${maxWidth}px` };
    const tooltipColor = lightTheme ? 'white' : undefined;
    return (React.createElement(Styled.Tooltip, Object.assign({ title: title, color: tooltipColor }, tooltipProps),
        React.createElement(Styled.TooltipChildren, { display: display }, children)));
};
export default Tooltip;
