const NEW_ORDER_EVENT_NAME = 'NEW_ORDER';
const $window = typeof window !== 'undefined' ? window : undefined;
const GOOGLE_ANALYTICS_SCRIPT_ID = 'google-analytics-api';
const GA_ID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID;
if ($window && GA_ID && !document.head.querySelector(`#${GOOGLE_ANALYTICS_SCRIPT_ID}`)) {
    const loadScript = document.createElement('script');
    loadScript.async = true;
    loadScript.id = GOOGLE_ANALYTICS_SCRIPT_ID;
    loadScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    document.head.appendChild(loadScript);
    const initScript = document.createElement('script');
    initScript.innerHTML = `window.dataLayer = window.dataLayer || [];
		function gtag(){window.dataLayer.push(arguments);}
		gtag('js', new Date());

		gtag('config', '${GA_ID}');`;
    document.head.appendChild(initScript);
}
export default class GoogleApi {
    static async sendAnalyticsEvent(eventAction, params) {
        gtag('event', eventAction, params);
    }
    static sendOrderCompleted(departmentId, caseId, orderId) {
        return new Promise(resolve => {
            if (typeof gtag !== 'function')
                return resolve(true);
            this.sendAnalyticsEvent(NEW_ORDER_EVENT_NAME, {
                event_category: departmentId,
                event_label: caseId,
                value: parseInt(orderId, 10),
                event_callback: () => resolve(true),
            });
        });
    }
}
