import React from 'react';
import Styled from './Styled';
import { Skeleton } from 'antd';
const Paragraph = props => {
    const title = { width: props.titleWidth };
    const rows = { rows: props.rows };
    const rowWidth = Array.isArray(props.rowWidth) ? { width: props.rowWidth } : { width: [props.rowWidth] };
    const paragraph = rowWidth ? Object.assign(rows, rowWidth) : rows;
    const loading = props.loading === undefined ? true : props.loading;
    const active = props.active;
    const antSkeletonProps = { active, title, paragraph, loading };
    return (React.createElement(Styled.ParagraphWrapper, { singleRow: props.rows === undefined || props.rows === 1 },
        React.createElement(Skeleton, Object.assign({}, antSkeletonProps), props.children)));
};
export default Paragraph;
