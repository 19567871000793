import { mapBranchFromDto, mapBranchToDto } from '../department/helpers';
import { IS_ESOFT_USER_KEY } from '../http';
import { UserAccess } from './models';
export function getInitialsFromFullName(fullName) {
    return fullName
        .split(/\s/)
        .reduce((acc, word) => (acc += word.slice(0, 1)), '')
        .slice(0, 2)
        .toUpperCase();
}
export function getInitials(firstName, lastName) {
    return `${firstName} ${lastName}`
        .split(/\s/)
        .reduce((acc, word) => (acc += word.slice(0, 1)), '')
        .slice(0, 2)
        .toUpperCase();
}
export function isInternalUser(email) {
    return !!(email === null || email === void 0 ? void 0 : email.match(/@esoft.com/));
}
export function isEsoftUser() {
    return typeof window !== 'undefined' ? localStorage.getItem(IS_ESOFT_USER_KEY) === 'true' : false;
}
export function mapWatcherFromDto({ axContactPersonId, userId, userEmail, userFirstName, userLastName, }) {
    return {
        id: userId,
        contactPersonId: axContactPersonId,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
    };
}
export function mapUserFromDto(userDto, authUserBranches) {
    var _a;
    const { id, email, firstName, lastName, fullName, phone, isFromEsoft, settings, currentlyImpersonatingEmail, currentlyImpersonatingName, currentlyImpersonatingUserId, contactPersonId, } = userDto;
    const branches = Array.isArray(authUserBranches) ? authUserBranches : userDto.branches;
    const currentFirstName = currentlyImpersonatingName ? (_a = currentlyImpersonatingName.split(' ')) === null || _a === void 0 ? void 0 : _a[0] : firstName;
    const currentLastName = currentlyImpersonatingName
        ? currentlyImpersonatingName.substring(currentFirstName.length)
        : lastName;
    const currentFullName = currentlyImpersonatingName || fullName || `${firstName} ${lastName}`;
    return {
        id: currentlyImpersonatingUserId || id,
        firstName: currentFirstName,
        lastName: currentLastName,
        phoneNumber: phone,
        fullName: currentFullName,
        initials: getInitialsFromFullName(currentFullName),
        email: currentlyImpersonatingEmail || email,
        isInternalUser: !isFromEsoft,
        visiblePrices: !!(settings === null || settings === void 0 ? void 0 : settings.showPrices),
        branches: branches.map(mapBranchFromDto),
        contactPersonId,
        _dto: userDto,
    };
}
export function mapUserToDto({ id, firstName, lastName, email, phoneNumber, branches, visiblePrices, _dto, }) {
    return {
        ..._dto,
        id,
        firstName,
        lastName,
        email,
        phone: phoneNumber,
        settings: {
            ..._dto === null || _dto === void 0 ? void 0 : _dto.settings,
            showPrices: visiblePrices,
        },
        branches: branches.filter(department => department.access !== UserAccess.None).map(mapBranchToDto),
    };
}
export function mapUserBranchFromDto(userBranchDto, branchId) {
    const { id, firstName, lastName, isAdmin, email, phone, contactPersonId } = userBranchDto;
    return {
        id,
        fullName: `${firstName} ${lastName !== null && lastName !== void 0 ? lastName : ''}`,
        branchId,
        email,
        phoneNumber: phone,
        currentBranchContactPersonId: contactPersonId,
        access: isAdmin ? UserAccess.Admin : UserAccess.Regular,
        _dto: userBranchDto,
    };
}
export function mapUserBranchToDto({ id, fullName, email, phoneNumber, access, _dto, }) {
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.substring(firstName.length);
    return {
        ..._dto,
        id,
        firstName,
        lastName,
        isAdmin: access === UserAccess.Admin,
        email,
        phone: phoneNumber,
    };
}
export function mapContactPersonFromDto({ name, contactType, contactInfo, }) {
    if (contactType === 'NONE') {
        return undefined;
    }
    return {
        type: contactType,
        name,
        email: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.email,
        phone: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.phoneMobile,
        phoneAlt: (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.phonePrivate) || (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.phoneWork),
    };
}
export function mapUserAccessToUser({ id, fullName, email, phoneNumber }) {
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.substring(firstName.length);
    return {
        id,
        firstName,
        lastName,
        email,
        phoneNumber,
    };
}
