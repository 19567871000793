import { ChevronDown24, ChevronUp24 } from '@carbon/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import Styled from './Styled';
const Expandable = ({ className, disabled = false, expanded, onExpandedChange, children, tooltipTitle, }) => {
    const childrenRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(9999);
    useEffect(() => {
        const updateMaxHeight = () => { var _a, _b; return setMaxHeight((_b = (_a = childrenRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : 9999); };
        updateMaxHeight();
        window.addEventListener('resize', updateMaxHeight);
        window.addEventListener('orientationchange', updateMaxHeight);
        return () => {
            window.removeEventListener('resize', updateMaxHeight);
            window.removeEventListener('orientationchange', updateMaxHeight);
        };
    }, []);
    return (React.createElement(Styled.Wrapper, { className: className, "$disabled": disabled },
        React.createElement(Styled.Content, { "$disabled": disabled },
            React.createElement(Styled.ContentInner, { "$disabled": disabled, "$expanded": expanded, "$maxHeight": maxHeight },
                React.createElement("div", { ref: childrenRef }, children))),
        !disabled && (React.createElement(Styled.ExpandButton, { title: tooltipTitle, shape: 'circle', onClick: () => onExpandedChange(!expanded) }, expanded ? React.createElement(ChevronUp24, null) : React.createElement(ChevronDown24, null)))));
};
export default Expandable;
