import theme from './theme';
// Reference:
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
export default {
    // Screens
    'screen-xs': `${theme.breakpoints.xs}px`,
    'screen-sm': `${theme.breakpoints.sm}px`,
    'screen-md': `${theme.breakpoints.md}px`,
    'screen-lg': `${theme.breakpoints.lg}px`,
    'screen-xl': `${theme.breakpoints.xl}px`,
    'screen-xxl': `${theme.breakpoints.xxl}px`,
    // base colors (palettes are generated by less using the same algorithm)
    'primary-color': theme.colors.primary['500'],
    'info-color': theme.colors.info['500'],
    'success-color': theme.colors.success['500'],
    'processing-color': theme.colors.info['500'],
    'error-color': theme.colors.error['500'],
    'highlight-color': theme.colors.error['500'],
    'warning-color': theme.colors.warning['500'],
    'normal-color': theme.colors.gray['500'],
    white: theme.colors.gray['000'],
    black: theme.colors.gray['900'],
    // primary palette
    'primary-1': theme.colors.primary['000'],
    'primary-2': theme.colors.primary['100'],
    'primary-3': theme.colors.primary['200'],
    'primary-4': theme.colors.primary['300'],
    'primary-5': theme.colors.primary['400'],
    'primary-6': theme.colors.primary['500'],
    'primary-7': theme.colors.primary['600'],
    'primary-8': theme.colors.primary['700'],
    'primary-9': theme.colors.primary['800'],
    'primary-10': theme.colors.primary['900'],
    // other colors
    'body-background': theme.colors.gray['000'],
    'component-background': theme.colors.gray['000'],
    'background-color-light': theme.colors.gray['100'],
    // typography
    'font-family': theme.typography.family,
    'font-size-base': `${theme.typography.sizes.md}px`,
    'font-size-lg': `${theme.typography.sizes.lg}px`,
    'font-size-sm': `${theme.typography.sizes.sm}px`,
    'line-height-base': theme.typography.lineHeightBase,
    // border radii
    'border-radius-base': `${theme.radii.md}px`,
    'border-radius-sm': `${theme.radii.sm}px`,
    // paddings
    'padding-lg': `${theme.paddings.lg}px`,
    'padding-md': `${theme.paddings.md}px`,
    'padding-sm': `${theme.paddings.sm}px`,
    'padding-xs': `${theme.paddings.xs}px`,
    'padding-xss': `${theme.paddings.xxs}px`,
    // margins
    'margin-lg': `${theme.margins.lg}px`,
    'margin-md': `${theme.margins.md}px`,
    'margin-sm': `${theme.margins.sm}px`,
    'margin-xs': `${theme.margins.xs}px`,
    'margin-xss': `${theme.margins.xxs}px`,
    // heights
    'height-base': `${theme.heights.md}px`,
    'height-lg': `${theme.heights.lg}px`,
    'height-sm': `${theme.heights.sm}px`,
    // easing
    'ease-base-out': theme.easing.baseOut,
    'ease-base-in': theme.easing.baseIn,
    'ease-out': theme.easing.out,
    'ease-in': theme.easing.in,
    'ease-in-out': theme.easing.inOut,
    'ease-out-back': theme.easing.outBack,
    'ease-in-back': theme.easing.inBack,
    'ease-in-out-back': theme.easing.inOutBack,
    'ease-out-circ': theme.easing.outCirc,
    'ease-in-circ': theme.easing.inCirc,
    'ease-in-out-circ': theme.easing.inOutCirc,
    'ease-out-quint': theme.easing.outQuint,
    'ease-in-quint': theme.easing.inQuint,
    'ease-in-out-quint': theme.easing.inOutQuint,
    // shadows
    'shadow-1-up': theme.shadows.top,
    'shadow-1-down': theme.shadows.bottom,
    'shadow-1-left': theme.shadows.left,
    'shadow-1-right': theme.shadows.right,
    'shadow-2': theme.shadows.box,
    // layout vars (hard-coded to absolute values by less so we're going to overwrite them)
    'layout-header-height': `${theme.heights.xxl}px`,
    'layout-header-padding': `0`,
    'layout-header-background': theme.colors.gray['000'],
    'layout-body-background': theme.colors.gray['000'],
    'layout-footer-padding': `${`${theme.paddings.lg}px`} ${`${theme.paddings.xl}px`}`,
    'layout-footer-background': theme.colors.gray['100'],
    // checkbox size (hard-coded to absolute)
    'checkbox-size': `${theme.heights.xs}px`,
    // card
    'card-padding-base': `${theme.paddings.xl}px`,
    'card-padding-base-sm': `${theme.paddings.lg}px`,
    // Input placeholder color
    'input-placeholder-color': theme.colors.gray['600'],
    // Avatar
    'avatar-size-base': `${theme.heights.sm}px`,
    'avatar-size-sm': `${theme.heights.xs}px`,
    'avatar-size-lg': `${theme.heights.xl}px`,
    // Button
    'btn-font-size-sm': `${theme.typography.sizes.sm}px`,
    'btn-font-size-lg': `${theme.typography.sizes.md}px`,
    // Borders
    'border-color-base': theme.colors.primary['200'],
    'border-color-split': theme.colors.primary['200'],
    // Tables
    'table-header-bg': theme.colors.gray['000'],
    'table-header-color': theme.colors.gray['700'],
    'table-header-sort-bg': theme.colors.gray['000'],
    'table-padding-vertical': `${theme.paddings.sm}px`,
    'table-padding-horizontal': `${theme.paddings.sm}px`,
    // Select
    'select-single-item-height-lg': `${theme.heights.lg}px`,
    'select-multiple-item-height-lg': `${theme.heights.lg}px`,
    // Radio
    'radio-size': `${theme.heights.xxs}px`,
    'radio-dot-size': `${theme.heights.xxs * 0.5}px`,
    // Modal
    'modal-header-padding': `${theme.paddings.lg}px`,
    'modal-body-padding': `0 ${theme.paddings.lg}px`,
    'modal-header-border-width': 0,
    'modal-header-title-line-height': `${theme.heights.sm}px`,
    'modal-header-title-font-size': `${theme.typography.sizes.xl}px`,
    'modal-header-close-size': `${theme.heights.sm}px`,
    'modal-close-color': theme.colors.primary['500'],
    'modal-footer-border-width': 0,
    'modal-footer-padding-vertical': `${theme.paddings.xl}px`,
    'modal-footer-padding-horizontal': `${theme.paddings.xl}px`,
    // Divider
    'divider-color': theme.colors.primary['200'],
    // Form
    'form-item-margin-bottom': `${theme.margins.md}px`,
};
