import { fixDateInstance } from '@libs/util';
import dayjs from 'dayjs';
import { mapFullAddressFromDto } from '../address/helpers';
import { TaskStatus, TaskCategory, } from './models';
const STATUS_MAP = {
    Completed: TaskStatus.Complete,
    Open: TaskStatus.Open,
    Partial: TaskStatus.Partial,
    Cancelled: TaskStatus.Cancelled,
    Unknown: TaskStatus.Unknown,
};
const CATEGORY_MAP = {
    bookPhotographer: TaskCategory.BookPhotographer,
    uploadImages: TaskCategory.UploadImages,
    uploadVideos: TaskCategory.UploadVideos,
    virtualStaging: TaskCategory.VirtualStaging,
    PERSON: TaskCategory.Person,
    BASIC: TaskCategory.Basic,
    BASIC_OPT: TaskCategory.BasicOpt,
    INTRO_OUTRO_SPEAK: TaskCategory.IntroOutroSpeak,
    COPYWRITETEXTS: TaskCategory.CopywriteTexts,
    TEXT: TaskCategory.Text,
    ARIAL: TaskCategory.Arial,
    COMTEXT: TaskCategory.ComText,
    INTRO_OUTRO: TaskCategory.IntroOutro,
    PERSON_INFO: TaskCategory.PersonInfo,
    SPEAK_INPUT: TaskCategory.SpeakInput,
    VIDIBILI_COMMENT: TaskCategory.VidibiliComment,
    combineImages: TaskCategory.CombineImages,
    virtualStaging720: TaskCategory.VirtualStaging720,
    virtualStaging720Commercial: TaskCategory.VirtaulStagingCommercial720,
    virtualStagingCommercial: TaskCategory.VirtualStagingCommercial,
    virtualStagingInDoorRenovation: TaskCategory.VirtualStagingIndoorRenovation,
    virtualStagingOutDoorRenovation: TaskCategory.VirtualStagingOutdoorRenovation,
    virtualStagingRemovalOfObjects: TaskCategory.VirtualStagingRemoveObjects,
    LOCATION: TaskCategory.Location,
    beforeAndAfter: TaskCategory.BeforeAfter,
    'TEXT-STYLE': TaskCategory.NoStyleText,
    SoMeType: TaskCategory.SoMe,
    SoMeSlideshow: TaskCategory.SoMeSlideshow,
    SomeGif: TaskCategory.SomeGif,
    imageSelector: TaskCategory.ImageSelector,
};
export function mapTaskStatusFromDto(dtoStatus) {
    return STATUS_MAP[dtoStatus];
}
export function mapTaskStatusToDto(status) {
    return Object.keys(STATUS_MAP).find(key => STATUS_MAP[key] === status);
}
export function mapTaskCategoryFromDto(dtoCategory) {
    return CATEGORY_MAP[dtoCategory];
}
export function mapTaskCategoryToDto(category) {
    return Object.keys(CATEGORY_MAP).find(key => CATEGORY_MAP[key] === category);
}
export function mapTaskFiltersFromDto({ types, branches, statuses }) {
    return {
        categories: types.map(mapTaskCategoryFromDto),
        statuses: statuses.map(mapTaskStatusFromDto),
        branches,
    };
}
export function mapTaskOrderByParamToDto(orderBy) {
    return (orderBy &&
        {
            createdAt: 'creationdate',
            branchName: 'branchname',
            caseId: 'reference',
            type: 'tasktype',
            status: 'status',
            productName: 'productname',
        }[orderBy]);
}
export function mapVirtualStagingFromDto({ imageUrl, roomType, furnitures }) {
    return {
        roomTypeName: roomType,
        url: imageUrl,
        isSelected: false,
        interiorOptions: furnitures.map(({ code, imageUrl: url }) => ({
            name: code,
            url,
            isSelected: false,
        })),
    };
}
function normalizeBookingPhotographerData({ proposal, duration, bookingId, }) {
    const arrival = new Date(proposal.expectedStart), departure = dayjs(arrival).add(duration, 'm').toDate(), start = new Date(proposal.timeframeStart), end = new Date(proposal.timeframeEnd);
    let area, info;
    if (proposal.notes) {
        const infoStr = proposal.notes.trim();
        const timeRegExp = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/g;
        const lineBreakExp = /(?:<(?:br|BR) ?\/>)+/g;
        const areaExp = /(?:Area: )(.*)(?= m2)/g;
        // split string in array using <BR/> tag as separator
        const infoArr = infoStr.split(lineBreakExp).filter(str => {
            const parsedArea = str === null || str === void 0 ? void 0 : str.match(areaExp);
            // if element has Area info - get area and exclude this element from info array
            if (parsedArea) {
                area = parsedArea;
                return false;
            }
            else {
                return true;
            }
        });
        // if element has timestamp - cut it and return as separate property
        if (infoArr.length) {
            info = infoArr.map(str => {
                // format always the same
                if (~str.search(timeRegExp)) {
                    return {
                        time: new Date(str.trim().slice(0, 16)),
                        text: str.trim().slice(17),
                    };
                }
                else {
                    return { text: str };
                }
            });
        }
    }
    return {
        start,
        end,
        timeForTask: duration,
        info,
        area,
        arrival,
        departure,
        bookingId,
        id: proposal.proposalId,
        photographerName: proposal.photographerName ? proposal.photographerName : undefined,
    };
}
export function mapCallProposalFromDto(proposalResponse) {
    var _a;
    return {
        primary: proposalResponse.suggestedProposal
            ? normalizeBookingPhotographerData({
                proposal: proposalResponse.suggestedProposal,
                duration: proposalResponse.durationMinutes,
                bookingId: proposalResponse.bookingId,
            })
            : undefined,
        secondary: ((_a = proposalResponse.proposalList) === null || _a === void 0 ? void 0 : _a.map(proposal => normalizeBookingPhotographerData({
            proposal,
            duration: proposalResponse.durationMinutes,
            bookingId: proposalResponse.bookingId,
        }))) || [],
        duration: proposalResponse.durationMinutes,
    };
}
export function mapDatahubTaskFromDto(task) {
    const { branchId, branchName, caseId, caseReference, createdAt, id, productName, status, type, address, orderId, orderlineId, salesId, contactPersonUserID, } = task;
    return {
        _dto: task,
        branchId,
        branchName,
        caseId,
        caseReference,
        createdAt: new Date(fixDateInstance(createdAt)),
        id,
        productName,
        status: mapTaskStatusFromDto(status),
        type: mapTaskCategoryFromDto(type),
        address: address && mapFullAddressFromDto(address),
        orderId,
        orderlineId,
        salesId,
        contactPersonId: contactPersonUserID,
    };
}
export function mapInterviewQuestionFromDto({ question, yesOrNoQuestion, answer, }) {
    return {
        question,
        answer,
        yesOrNo: yesOrNoQuestion,
    };
}
