import { FeedbackDrawingShape, FeedbackType, OrderStatus, } from './models';
import { diffPoints, fitPointToSize, sumPoints, fixDateInstance } from '@libs/util';
import { mapFullAddressFromDto } from '../address/helpers';
const ORDER_STATUS_MAP = {
    Open: OrderStatus.Open,
    Delivered: OrderStatus.Delivered,
    Canceled: OrderStatus.Cancelled,
    Cancelled: OrderStatus.Cancelled,
    NoOrders: OrderStatus.None,
    None: OrderStatus.None,
    Invoiced: OrderStatus.Invoiced,
    'Open order': OrderStatus.Open,
    Backorder: OrderStatus.Backorder,
};
const FEEDBACK_TYPE_MAP = {
    [FeedbackType.Change]: 'change',
    [FeedbackType.Complaint]: 'complaint',
    [FeedbackType.Feedback]: 'feedback',
};
export function isFeedbackArrowOrPoint(drawing) {
    return drawing.shape === FeedbackDrawingShape.Point || drawing.shape === FeedbackDrawingShape.Arrow;
}
export function isFeedbackLasso(drawing) {
    return drawing.shape === FeedbackDrawingShape.Lasso;
}
export function mapOrderStatusFromDto(dtoStatus) {
    return ORDER_STATUS_MAP[dtoStatus];
}
export function mapOrderStatusToDto(status) {
    return Object.keys(ORDER_STATUS_MAP).find(key => ORDER_STATUS_MAP[key] === status);
}
export function mapSimplifiedOrderFromDto({ id, salesId, status, createdDate, currency, endUserInvoicing, contactPersonUserID, }) {
    return {
        id,
        salesId,
        status: mapOrderStatusFromDto(status),
        createdDate: new Date(fixDateInstance(createdDate)),
        currency,
        responsibleUserId: contactPersonUserID,
        endUserInvoice: endUserInvoicing && {
            firstName: endUserInvoicing.firstName,
            lastName: endUserInvoicing.lastName,
            email: endUserInvoicing.email,
            phone: endUserInvoicing.phone,
            address: mapFullAddressFromDto({
                buildingDoor: endUserInvoicing.addressBuildingDoor,
                buildingFloor: endUserInvoicing.addressBuildingFloor,
                buildingNumber: endUserInvoicing.addressBuildingNumber || '',
                city: endUserInvoicing.addressCity || '',
                cityArea: endUserInvoicing.addressCityArea,
                country: endUserInvoicing.addressCountry,
                street: endUserInvoicing.addressStreet || '',
                zip: endUserInvoicing.addressZip || '',
            }),
        },
    };
}
export function mapDatahubOrderDtoToSimplifiedOrder({ id, salesId, salesStatus, createdAt, currencyCode, contactPersonUserID, totalBalance, }) {
    return {
        id,
        salesId,
        status: mapOrderStatusFromDto(salesStatus),
        createdDate: new Date(fixDateInstance(createdAt)),
        currency: currencyCode,
        responsibleUserId: contactPersonUserID,
        totalPrice: totalBalance || 0,
    };
}
export function getFeedbackObjectMeta(drawing, canvas, label) {
    if (isFeedbackArrowOrPoint(drawing)) {
        const { shape, p0, p1 } = drawing;
        if (shape === FeedbackDrawingShape.Point) {
            const center = fitPointToSize(p0, canvas.size, canvas.pointDrawingSize * 0.5);
            const start = diffPoints(center, [canvas.pointDrawingSize * 0.5, canvas.pointDrawingSize * 0.5]);
            const end = sumPoints(center, [canvas.pointDrawingSize * 0.5, canvas.pointDrawingSize * 0.5]);
            return {
                kind: 'Rect',
                color: canvas.drawingColor,
                label,
                labelPoint: { x: Math.round(center[0]), y: Math.round(center[1]) },
                p1: { x: Math.round(start[0]), y: Math.round(start[1]) },
                p2: { x: Math.round(end[0]), y: Math.round(end[1]) },
            };
        }
        else {
            const center = fitPointToSize(p0, canvas.size, canvas.arrowDrawingSize * 0.5);
            return {
                kind: 'Arrow',
                color: canvas.drawingColor,
                label,
                labelPoint: { x: Math.round(center[0]), y: Math.round(center[1]) },
                p1: { x: Math.round(center[0]), y: Math.round(center[1]) },
                p2: { x: Math.round(p1[0]), y: Math.round(p1[1]) },
            };
        }
    }
    else {
        return {
            kind: 'Lasso',
            color: canvas.drawingColor,
            label,
            labelPoint: { x: drawing.labelPoint[0], y: drawing.labelPoint[1] },
            // points are not needed, left for backend compatibility
            p1: { x: 0, y: 0 },
            p2: { x: 0, y: 0 },
        };
    }
}
export function transformCanvasDocumentToValid(canvas, drawing, index) {
    const { comment: drawingComment } = drawing;
    const label = String(index + 1);
    return {
        comment: `${label}: ${drawingComment}`,
        ...getFeedbackObjectMeta(drawing, canvas, label),
    };
}
export function mapFeedbackParamsToDto({ type, comment, orderlineId, uploadedDocuments, drawingComments, }) {
    return {
        orderlineId,
        type: FEEDBACK_TYPE_MAP[type],
        uploadedDocuments,
        comment: `${comment}\n\n${drawingComments.join('\n')}`,
    };
}
export function mapBookingInfoFromDto({ photographer, tourPlanningTimeFrameEnd, tourPlanningTimeFrameStart, tourDate, notes, keyPickupAddress, bookingId, status, }) {
    return {
        bookingId,
        bookingEndsAt: tourDate && tourPlanningTimeFrameEnd
            ? new Date(`${tourDate.substring(0, 10)}${fixDateInstance(tourPlanningTimeFrameEnd.substring(10))}`)
            : undefined,
        bookingStartsAt: tourDate && tourPlanningTimeFrameStart
            ? new Date(`${tourDate.substring(0, 10)}${fixDateInstance(tourPlanningTimeFrameStart.substring(10))}`)
            : undefined,
        photographerName: photographer.name,
        comments: notes || '',
        pickUpAddress: keyPickupAddress,
        status: status,
    };
}
export function mapDatahubOrderLineFromDto({ id, batchId, salesTotal, salesQty, deliveredQty, status, productName, deliveryDate, salesUnit, feedbackEnabled, vendorOrderLineId, }) {
    return {
        id,
        batchId,
        productName,
        status: status.toUpperCase(),
        orderedQuantity: salesQty,
        deliveredQuantity: deliveredQty,
        price: salesTotal,
        deliveredAt: deliveryDate ? new Date(fixDateInstance(deliveryDate)) : undefined,
        unit: salesUnit,
        canReceiveFeedback: !!feedbackEnabled,
        vendorOrderLineId,
    };
}
export function mapPublicBookingFromDto({ orderId, status }) {
    return { orderId, status };
}
export function mapPropertyTypeFromDto({ typeName, translations }, i18nLanguage) {
    var _a, _b;
    return {
        key: typeName,
        name: ((_a = translations.find(({ languageCode }) => languageCode === i18nLanguage)) === null || _a === void 0 ? void 0 : _a.translation) ||
            ((_b = translations.find(({ languageCode }) => languageCode === 'en')) === null || _b === void 0 ? void 0 : _b.translation) ||
            translations[0].translation,
    };
}
