/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { useMemo } from 'react';
import { Select as FormikSelect } from 'formik-antd';
import { useFormikContext } from 'formik';
import Styled from './Styled';
import Anchor from '../Anchor';
import { Typography, Select } from 'antd';
import { useTheme } from 'styled-components';
import { useLocalization } from '@libs/localization';
const MultiselectFilter = ({ name, allSelectedPlaceholder = 'All selected', maxTagCount = 1, options, onSelectAll, onDeselectAll, ...restProps }) => {
    const form = useFormikContext();
    const { t } = useLocalization();
    const value = ((form === null || form === void 0 ? void 0 : form.values[name]) instanceof Array ? form.values[name] : []);
    const selectedOptionsCount = value.length;
    const totalOptionsCount = options.length;
    const areAllOptionsSelected = selectedOptionsCount === totalOptionsCount && totalOptionsCount > 0;
    const theme = useTheme();
    const listItemHeight = theme.heights.xxs + // base checkbox height
        2 * theme.paddings.xxs + // padding top & bottom
        theme.margins.xxs; // margin to next item
    const Component = useMemo(() => (form ? FormikSelect : Select), [form]);
    return (React.createElement(Component, Object.assign({ name: name, mode: 'multiple', maxTagCount: areAllOptionsSelected ? 0 : maxTagCount, maxTagPlaceholder: omittedTags => areAllOptionsSelected ? allSelectedPlaceholder : `+${omittedTags.length} more`, listItemHeight: listItemHeight, dropdownRender: children => (React.createElement(Styled.DropdownWrapper, null,
            React.createElement(Styled.SelectionControlsWrapper, null,
                React.createElement(Anchor, { underline: true, onClick: e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (typeof onSelectAll === 'function') {
                            onSelectAll(name);
                        }
                        else {
                            form === null || form === void 0 ? void 0 : form.setFieldValue(name, options.map(option => option.value), false);
                        }
                    } }, t('common.terms.selectAll')),
                React.createElement(Typography.Text, null, "\u00A0/\u00A0"),
                React.createElement(Anchor, { underline: true, onClick: e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (typeof onDeselectAll === 'function') {
                            onDeselectAll(name);
                        }
                        else {
                            form === null || form === void 0 ? void 0 : form.setFieldValue(name, [], false);
                        }
                    } }, t('common.terms.deselectAll'))),
            children)), options: options }, restProps)));
};
export default MultiselectFilter;
