import { ArrowLeft24, ArrowRight24, Close24 } from '@carbon/icons-react';
import { useScreenMatch } from '@libs/theme';
import React, { Children, createContext, Fragment, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useHideModal } from '../context';
import Styled from './Styled';
const SizeUpdateContext = createContext(() => {
    /* */
});
const TRANSITION_DURATION = 500;
const SIZE_TRANSITION_DURATION_PER_PX = 1;
const CarouselModal = ({ current, onChange, children }) => {
    var _a;
    const hideModal = useHideModal();
    const match = useScreenMatch();
    const slides = Children.toArray(children);
    const preloaderRef = useRef(null);
    const [size, setSize] = useState([0, 0]);
    const prevSizeRef = useRef(size);
    const updateSize = useCallback((index) => {
        var _a;
        const slideEl = (_a = preloaderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(`:nth-child(${index + 1})`);
        if (slideEl) {
            setSize(prevSize => {
                prevSizeRef.current = prevSize;
                return [slideEl.clientWidth, slideEl.clientHeight];
            });
        }
    }, []);
    // Update size when the slide changes
    useEffect(() => updateSize(current), [current, updateSize]);
    const onPrevClick = useCallback(() => {
        onChange(current === 0 ? slides.length - 1 : current - 1);
    }, [current, onChange, slides.length]);
    const onNextClick = useCallback(() => {
        onChange(current === slides.length - 1 ? 0 : current + 1);
    }, [current, onChange, slides.length]);
    useEffect(() => {
        const onKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowLeft':
                    onPrevClick();
                    break;
                case 'ArrowRight':
                    onNextClick();
                    break;
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onPrevClick, onNextClick]);
    const isCurrentImage = !!((_a = preloaderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(`:nth-child(${current + 1}) > img`));
    return (React.createElement(Styled.Modal, { visible: true, closeIcon: React.createElement(Close24, null), footer: null, width: 'auto', "$width": size[0], "$height": size[1], "$widthTransitionDuration": Math.abs((size[0] - prevSizeRef.current[0]) * SIZE_TRANSITION_DURATION_PER_PX), "$heightTransitionDuration": Math.abs((size[1] - prevSizeRef.current[1]) * SIZE_TRANSITION_DURATION_PER_PX), onCancel: hideModal },
        React.createElement(SizeUpdateContext.Provider, { value: (source) => {
                if (slides[current].props.source === source) {
                    updateSize(current);
                }
            } },
            React.createElement(Styled.Preloader, { ref: preloaderRef }, children),
            React.createElement(Styled.PrevButton, { size: match.map({ xxs: 'middle', md: 'large' }), onClick: onPrevClick },
                React.createElement(ArrowLeft24, null)),
            React.createElement(Styled.TransitionGroup, { "$duration": TRANSITION_DURATION, "$imageContainer": isCurrentImage },
                React.createElement(CSSTransition, { key: current, timeout: TRANSITION_DURATION, classNames: 'transition' }, slides[current])),
            React.createElement(Styled.NextButton, { size: match.map({ xxs: 'middle', md: 'large' }), onClick: onNextClick },
                React.createElement(ArrowRight24, null)))));
};
const Slide = ({ type, source, fallbackImageSource }) => {
    const [shouldUseFallback, setShouldUseFallback] = useState(false);
    const updateSize = useContext(SizeUpdateContext);
    const onError = () => {
        if (fallbackImageSource) {
            setShouldUseFallback(true);
        }
    };
    const onLoad = () => updateSize(source);
    // Reset fallback when source changes
    useEffect(() => {
        if (shouldUseFallback) {
            setShouldUseFallback(false);
        }
    }, [source]);
    return (React.createElement(Styled.Slide, { "$type": shouldUseFallback ? 'image' : type }, shouldUseFallback ? (React.createElement("img", { src: fallbackImageSource, alt: 'Slide image', onLoad: onLoad })) : (React.createElement(Fragment, null,
        type === 'image' && React.createElement("img", { src: source, alt: 'Carousel slide', onError: onError, onLoad: onLoad }),
        type === 'video' && (React.createElement("video", { controls: true, autoPlay: true, muted: true, onError: onError, onLoad: onLoad, onLoadedMetadata: onLoad },
            React.createElement("source", { src: source }))),
        type === 'embedCode'.toLowerCase() && (React.createElement("div", { dangerouslySetInnerHTML: {
                __html: source,
            } })),
        type === 'iframe' && (React.createElement("iframe", { src: source, frameBorder: "0", allowFullScreen: true, allow: "autoplay; mute; fullscreen", onError: onError, onLoad: onLoad }))))));
};
CarouselModal.Slide = Slide;
export default CarouselModal;
